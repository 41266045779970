import React, { Component } from "react";
import { Link } from "react-router-dom";
import ExposureReportCard from "./ExposureReportCard";

class LeftStatCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            this_week: 0,
            total: 0,
            total_courses: 0,
        };
    }

    componentDidMount() {
        /*
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + this.props.access_token);
            let requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };
            let url = "https://api.edvolve.in/rest/students/"+this.props.student_id+"/experiences/count";
                fetch(url, requestOptions)
                .then(res => res.json())
                .then(
                  (result) => {
                        this.setState({
                        total: result.data.overall_count,
                        this_week: result.data.this_week_count
                    });
                  },
                  (error) => {
                  }
                    );
                */
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.props.access_token);

        let requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        let url1 = `${process.env.REACT_APP_BASE_URL}/rest/students/${this.props.student_id}/experiences/count`;
        let url2 = `${process.env.REACT_APP_BASE_URL}/rest/students/${this.props.student_id}/courses/count`;

        Promise.all([fetch(url1, requestOptions), fetch(url2, requestOptions)])
            .then(([res1, res2]) => {
                return Promise.all([res1.json(), res2.json()]);
            })
            .then(
                ([result1, result2]) => {
                    this.setState({
                        total: result1.data?.overall_count,
                        this_week: result1.data?.this_week_count,
                        total_courses: result2.data?.overall_count,
                    });
                },
                (error) => { }
            );
    }

    render() {
        return (
            <div className="col-12 col-lg-4 dashboard-cards-wrapper">
                <div className="left-stat-div row">
                    <h4 style={{ marginTop: "12px" }}>Your Stats</h4>
                    {/* <div className="col-sm-4 col-md-4 col-lg-4 col-xs-4 experience-box-card">
					<div className="experience-count-dashboard-card">
						<span className="experience-count-dashboard">{this.state.this_week}</span>
						<span>Experiences this week</span>
					</div>
				</div> */}

                    <div className="d-flex">
                        <div className="experience-box-card col-6">
                            <Link className="stat-card-link" to="/past-recordings/experience">
                                <div className="experience-count-dashboard-card">
                                    <span className="experience-count-dashboard">
                                        {this.state.total}
                                    </span>
                                    <span>Workshops Completed</span>
                                </div>
                            </Link>
                        </div>
                        <div className="experience-box-card col-6">
                            <Link className="stat-card-link" to="/past-recordings/course">
                                <div className="experience-count-dashboard-card">
                                    <span className="experience-count-dashboard">
                                        {this.state.total_courses}
                                    </span>
                                    <span>Courses Completed</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <ExposureReportCard
                        access_token={this.props.access_token}
                        student_id={this.props.student_id}
                    />
                </div>
            </div>
        );
    }
}

export default LeftStatCard;
