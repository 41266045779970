import React, { Component } from "react";
// import Title from "./Title";
// import SnippetCourse from "../CourseHome/Snippet";
// import SnippetExp from "../ExperienceHome/Snippet";
import SnippetCourse from "../CourseHome/NewSnippet";
import SnippetExp from "../ExperienceHome/NewSnippet";
import LazyLoad from "../Commons/LazyLoad";

class MentorsCourse extends Component {
	constructor(props) {
		super(props);
		this.state = {
			exp_snippets: [],
		};
	}

	componentDidMount() {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/learn?mentor_id=${this.props.mentor_id}`;

		fetch(url, requestOptions)
			.then(function (response) {
				let status = response.status;
				let json_response = response.json();
				if (status !== 200) {
					if (status === 401) {
						throw new Error("Your session has expired. Please login again.");
					} else {
						throw new Error("Something went wrong. Please contact support.");
					}
				} else {
					return json_response;
				}
			})
			.then(
				(result) => {
					// console.log(result["data"]["results"]);
					this.setState({
						exp_snippets: result["data"]["results"],
					});
					// console.log(this.state.exp_snippets);
				},
				(error) => {}
			);
	}

	render() {
		return (
			<div>
				{this.state.exp_snippets.length > 0 ? (
					<div className="row mentor-webinar">
						<div className="row margin-minus-five margin-minus-twenty-desktop">
							{this.state.exp_snippets.map((snippet, index) => (
								<>
									{snippet.type === "experience" ? (
										<SnippetExp type="upcoming" snippet={snippet} />
									) : (
										<SnippetCourse type="upcoming" snippet={snippet} />
									)}
								</>
							))}
						</div>
					</div>
				) : (
					<div
						className="no-upcoming-webinar mt-4"
						style={{ flexDirection: "column" }}
					>
						<LazyLoad
							src="/icons/calendar.png"
							alt="calendar-dashboard"
							classes="calendar-dashboard"
							style={{ maxWidth: "200px" }}
						/>
						<p className="no-bookings-yet m-1">No classes added by the mentor.</p>
					</div>
				)}
			</div>
		);
	}
}

export default MentorsCourse;
