import React, { Component } from "react";
import { Redirect } from "react-router";
import Header from "../Header";

import LeftStatCard from "./LeftStatCard";
import UpcomingExperienceCourse from "./UpcomingExperienceCourse";
import Mentors from "./Mentors";

import Footer from "../Footer";
import { ThemeContext } from "../../context/ThemeContext";

class Dashboard extends Component {
	static contextType = ThemeContext;

	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	}

	componentDidMount() {
		document.title = "Dashboard | HelloWorlds";
	}

	render() {
		if (this.props.access_token === null) {
			return <Redirect to="/login" />;
		}

		return (
			<div>
				<Header
					update_token={this.props.update_token}
					current_route={this.props.current_route}
					avatar_url={this.props.avatar_url}
					access_token={this.props.access_token}
				/>

				<video className="video-background" autoPlay loop muted>
					<source src="/bg.mp4" type="video/mp4" />
					Your browser does not support the video tag.
				</video>

				<div className="dashboard-body row justify-content-center video-bg-content">
					<div className="student-welcome-dashboard" style={{ marginBottom: "30px" }}>
						<h2 className="text-white">
							👋 Hello,{" "}
							{this.props.student.first_name
								? this.capitalizeFirstLetter(this.props.student.first_name)
								: this.props.student.first_name}
							!
						</h2>
						<p className="ps-3">Be awesome everyday🔥</p>
					</div>
					<div className="row no-mob-padding">
						<UpcomingExperienceCourse
							key={this.props.student_id}
							student_id={this.props.student_id}
							access_token={this.props.access_token}
							student_uuid={this.props.student_uuid}
							dark={this.props.dark}
						/>
						{/* <LeftStatCard
							access_token={this.props.access_token}
							student_uuid={this.props.student_uuid}
							student_id={this.props.student_id}
							student={this.props.student}
						/> */}
						<Mentors
							access_token={this.props.access_token}
							student_uuid={this.props.student_uuid}
							student_id={this.props.student_id}
							student={this.props.student}
							history={this.props.history}
							dark={this.props.dark}
						/>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default Dashboard;
