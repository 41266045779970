import styles from "./Feed.module.css";
import { Col, Row, Container } from "react-bootstrap";
import ActivityCard from "./ActivityCard";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import UserProfileSidebar from "../UserProfileSidebar";
import LazyLoad from "../../Commons/LazyLoad";
import LikedModal from "../LikedModal";

/**
 * For handling profile picture
 * @param {string} image url
 * @returns {string} url if image is not null or undefined or empty string else default image
 */
const handleProfilePic = (img) => {
	if (img === null || img === undefined) {
		return "/student.png";
	} else if (img === "" || img === "NA") {
		return "/student.png";
	} else {
		return img;
	}
};

const Feed = (props) => {
	const [activity, setActivity] = useState([]); // initial posts
	const [allActivity, setAllActivity] = useState([]); // all posts
	const activeMembers = props.worldMembers?.filter(
		(member) => member?.sub_status === "ACTIVE"
	);

	const [likedModal, setLikedModal] = useState(false); // Show who liked the post
	const [likedModalData, setLikedModalData] = useState([]); // Data of who liked the post

	const [loading, setLoading] = useState(true);

	const FEED_LENGTH = activity.length; // initial posts length
	const DATA_LENGTH = allActivity.length; // total posts length

	let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/${props.match.params.id}/feed`;
	let myHeaders = new Headers();
	myHeaders.append("Authorization", "Bearer " + props.access_token);

	let requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow",
	};

	const fetchActivity = async () => {
		try {
			const response = await fetch(url, requestOptions);
			const data = await response.json();
			setActivity(data.data.results.slice(0, 8)); // setting 8 initial posts
			setAllActivity(data.data.results);
			props.setFeedLenght(data.data.results.length); // setting all posts
			setLoading(false);
		} catch (error) {
			console.error("Activity Error : ", error);
		}
	};

	// Fetching more posts for infinite scroll
	const fetchMoreActivity = () => {
		setTimeout(() => {
			setActivity((data) => allActivity.slice(data.Length, data.length + 8)); // adding 8 more posts
		}, 100);
	};

	useEffect(() => {
		fetchActivity();
	}, []);

	const ActivitySkeleton = () => {
		return (
			<div
				style={{
					background: "var(--background_primary)",
					borderRadius: "30px",
					padding: window.innerWidth < 768 ? "0.5rem 1rem" : "1rem 2rem",
					boxShadow: "rgb(136 136 136 / 30%) 0px 2px 10px",
				}}
			>
				<SkeletonTheme baseColor="gray" highlightColor="white">
					<div className="d-flex align-items-center mb-3">
						<Skeleton inline circle height={40} width={40} />
						<p className="m-0 ms-3">
							<Skeleton inline height={25} width={200} />
						</p>
					</div>

					<div className="feed-skeleton">
						<Skeleton height="100%" width="100%" />
					</div>
					<div className="my-3">
						<div className="d-flex align-items-center mb-3">
							<Skeleton circle height={40} width={40} />
							<p className="m-0 ms-3" style={{ width: "70%" }}>
								<Skeleton height={35} />
							</p>
						</div>

						<div className="d-flex align-items-center">
							<Skeleton circle height={40} width={40} />
							<p className="m-0 ms-3">
								<Skeleton height={25} width={200} />
							</p>
						</div>
					</div>
				</SkeletonTheme>
			</div>
		);
	};

	return (
		<div className="mb-4 gopher-font">
			<h2 className="d-none d-md-block fw-bolder pb-4 ps-3">Activity Feed</h2>
			{loading ? (
				<SkeletonTheme baseColor="gray" highlightColor="white">
					<Skeleton
						containerClassName="no-upcoming-webinar justify-content-start"
						wrapper={ActivitySkeleton}
						count={5}
					/>
				</SkeletonTheme>
			) : activity.length === 0 ? (
				<>
					<h3 className="my-3 text-info fw-bolder">Nothing is posted yet</h3>
				</>
			) : props.sneakPeek ? (
				<div
					style={
						window.innerWidth < 768
							? props.dark
								? {
									boxShadow: "rgb(136 136 136 / 30%) 0px 2px 10px",
									overflow: "hidden",
									borderRadius: "30px",
								}
								: {
									boxShadow: "#d9d9d9 0px 2px 10px",
									overflow: "hidden",
									borderRadius: "30px",
								}
							: {}
					}
				>
					<Col
						md={8}
						xs={12}
						className={styles.activity}
						style={
							props.dark
								? {
									boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
									overflow: "hidden",
									borderRadius: "30px",
									background: "var(--background_primary)",
								}
								: {
									boxShadow: "#d9d9d9 0px 2px 10px",
									overflow: "hidden",
									borderRadius: "30px",
									background: "var(--background_primary)",
								}
						}
					>
						{activity?.slice(0, 4)?.map((activity, index) => (
							<Row key={index} className={styles["individual-activity"]}>
								<ActivityCard
									activity={activity}
									worlds={props.worlds}
									access_token={props.access_token}
									fetchActivity={fetchActivity}
									student={props.student}
									worldMembers={props.worldMembers}
									sneakPeek={props.sneakPeek}
									handleOpenProfileSidebar={props.handleOpenProfileSidebar}
									setLikedModal={setLikedModal}
									setLikedModalData={setLikedModalData}
								/>
							</Row>
						))}
						<Container className="d-flex justify-content-center flex-column align-items-center pb-5 pt-3">
							<h4>Subscribe to get full access</h4>
							<button
								className="pay-btn mt-2"
								onClick={() =>
									props.history.push(`/worlds/${props.match.params.id}`)
								}
							>
								Subscribe
							</button>
						</Container>
					</Col>
				</div>
			) : (
				<InfiniteScroll
					dataLength={FEED_LENGTH} // initial posts length
					next={fetchMoreActivity} // fetching more posts
					hasMore={FEED_LENGTH !== DATA_LENGTH} // when to call next
					loader={
						<h2
							className="my-3"
							style={{
								position: "relative",
								left: "32%",
							}}
						>
							<Spinner animation="border" variant="danger" />
						</h2>
					}
					style={
						window.innerWidth < 768
							? props.dark
								? {
									boxShadow: "rgb(136 136 136 / 30%) 0px 2px 10px",
									overflow: "hidden",
									borderRadius: "30px",
								}
								: {
									boxShadow: "#d9d9d9 0px 2px 10px",
									overflow: "hidden",
									borderRadius: "30px",
								}
							: {}
					}
				>
					<Col
						md={8}
						xs={12}
						className={styles.activity}
						style={
							props.dark
								? {
									boxShadow: window.innerWidth > 768 ? "rgb(136 136 136 / 30%) 0px 2px 20px": "",
									overflow: "hidden",
									borderRadius: "30px",
									background: "var(--background_primary)",
								}
								: {
									boxShadow: "#d9d9d9 0px 2px 10px",
									overflow: "hidden",
									borderRadius: "30px",
									background: "var(--background_primary)",
								}
						}
					>
						{activity.map((activity, index) => (
							<Row key={index} className={styles["individual-activity"]}>
								<ActivityCard
									activity={activity}
									worlds={props.worlds}
									access_token={props.access_token}
									fetchActivity={fetchActivity}
									student={props.student}
									worldMembers={props.worldMembers}
									handleOpenProfileSidebar={props.handleOpenProfileSidebar}
									setLikedModal={setLikedModal}
									setLikedModalData={setLikedModalData}
								/>
							</Row>
						))}
					</Col>
					{/* {DATA_LENGTH === FEED_LENGTH && (
									<Container
										className='d-flex justify-content-center flex-column align-items-center pb-5 pt-3'

									>
										<h4>
											All done! Explore more clubs
										</h4>
										<button
											className="my-2 fs-4 px-3 text-white"
											onClick={() => props.history.push('/worlds')}
										>
											Explore
										</button>
									</Container>
								)} */}
				</InfiniteScroll>
			)}

			{activeMembers?.length > 0 && (
				<div className={styles.clubMembers}>
					<div className={styles.clubMembersInner}>
						<h5 className="fw-bolder mb-4">{activeMembers?.length} Members</h5>
						<div className={styles.clubMembersWrapper}>
							{activeMembers?.map((member) => (
								<div
									key={member?.student_id}
									className={styles.clubMember}
									onClick={() => props.handleOpenProfileSidebar(member)}
								>
									<LazyLoad
										src={handleProfilePic(member?.uuid?.student?.avatar_url)}
										classes="me-2"
										width="50px"
										height="50px"
										style={{ borderRadius: "50%" }}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			)}

			{/*Showing who liked the post */}
			{likedModal && (
				<LikedModal
					show={likedModal}
					handleClose={() => setLikedModal(false)}
					dark={props.dark}
					data={likedModalData}
				/>
			)}

			{props.showProfileSidebar && (
				<UserProfileSidebar
					show={props.showProfileSidebar}
					handleClose={props.handleCloseProfileSidebar}
					dark={props.dark}
					data={props.profileSidebarData}
					sneakPeek={props.sneakPeek}
				/>
			)}
		</div>
	);
};

export default Feed;
