import React, { Component } from "react";
import Title from "./Title";
import { capitalizeName } from "../utils";

class MentorIntro extends Component {
	render() {
		return (
			<div className="row mentor-intro py-4">
				<Title title={capitalizeName(this.props.first_name)} />
				<p>{this.props.one_liner}</p>
				{this.props.city === null ? (
					<p></p>
				) : (
					<p>
						I am located in{" "}
						<span style={{ color: "var(--fontColor-primary)" }}>
							{this.props.city}
						</span>
						.
					</p>
				)}
			</div>
		);
	}
}

export default MentorIntro;
