import { Link } from "react-router-dom";
import styles from "./Activity.module.css";

const JoindedClubs = (props) => {
    return (
        props.clubs.length > 0 ? (
            props.clubs.map((club) => {
                return (
                    <div className="d-flex flex-column" key={club.world.id}>
                        <div className={styles.joinedClubItem}>
                            <Link to={`/worlds/${club.world.id}/activity`}>
                                {
                                    props.currentClub === club.world.id ? (
                                        <>
                                            <span className={styles.activeClub}></span>
                                            <img
                                                src={club?.world?.image}
                                                alt={club?.world?.title}
                                                title={club?.world?.title}
                                            />
                                        </>
                                    ) : (
                                        <img
                                            src={club?.world?.image}
                                            alt={club?.world?.title}
                                            title={club?.world?.title}
                                        />
                                    )
                                }
                            </Link>
                        </div>
                    </div>
                )
            })
        ) : (
            null
        )
    )
}

export default JoindedClubs