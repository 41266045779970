import Header from "../Header";
import Footer from "../Footer";
import SubHeader from "../SubHeader";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import LazyLoad from "../Commons/LazyLoad";
import styles from "./SingleClub.module.css";
import React, { useContext, useRef } from "react";
import swal from "sweetalert";
import LoginPopup from "../Auth/LoginPopup";
import ReactDOM from "react-dom";
import { ThemeContext } from "../../context/ThemeContext";
import { useEffect, useState } from "react";
import { extractUrlParamSubscription } from "../utils";
import ClubContent from "./ClubContent";
import SectionContent from "./SectionContent";
import { OverlayTrigger } from "react-bootstrap";
import moment from "moment/moment";
import Recommendations from "./Recommendations";
import SubHeaderWorlds from "./SubHeaderWorlds";
import { RWebShare } from "react-web-share";
import GoogleLoginSuggestion from "../Auth/GoogleLoginSuggestion";
import { LoginSuggestionContext } from "../../context/LoginSuggestionContext";

// getting the next subscription date
const nextSubDate = () => {
	const currentDate = moment().startOf('day');
	const newDate = currentDate.add(30, 'days');
	const formattedDate = newDate.format('MMM DD, YYYY');
	return formattedDate;
};

const SingleClub = (props) => {
	const dark = useContext(ThemeContext).isDark;
	const [world, setWorld] = useState(null);
	const worldId = props.match.params.id;
	const [isMemberOfWorld, setIsMemberOfWorld] = useState(false);
	const student_id = localStorage.getItem("student_uuid");
	let subscription = extractUrlParamSubscription();
	let alreadySubscribedMsg = "";
	const history = useHistory();
	const [playVideo, setPlayVideo] = useState(false);
	const [showScrollBtn, setShowScrollBtn] = useState(false);
	const [showSubHeader, setShowSubHeader] = useState(false);
	const [showGoogleLogin, setShowGoogleLogin] = useState(false);


	// scroll to an element
	const detailsRef = useRef(null);
	const requirementsRef = useRef(null);
	const mentorRef = useRef(null);

	const { showGoogleLoginSuggestion, setShowGoogleLoginSuggestion } = useContext(LoginSuggestionContext);

	// Scroll to a element
	const executeScroll = (ref) => {
		ref.current.scrollIntoView({ behavior: "smooth" });
	}

	// Get world details
	const fetchWorld = async () => {
		try {
			let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/`;
			let myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + props.access_token);

			let requestOptions = {
				method: "GET",
				headers: myHeaders,
				redirect: "follow",
			};
			const response = await fetch(`${url}${worldId}`, requestOptions);
			const data = await response.json();
			setWorld(data.data);
		} catch (error) {
			console.error(error);
		}
	};

	// Check if student is member of world
	const fetchWorldMembers = async () => {
		try {
			let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/`;
			let myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + props.access_token);

			let requestOptions = {
				method: "GET",
				headers: myHeaders,
				redirect: "follow",
			};
			const response = await fetch(`${url}${worldId}/members`, requestOptions);
			const data = await response.json();

			//checking if the user is a member of the club
			const memberExists = data.data.results.filter(
				(member) => member.student_id === student_id
			);
			const isMember = memberExists.some(
				(member) => member.sub_status === "ACTIVE"
			);
			setIsMemberOfWorld(isMember);
		} catch (error) {
			console.error(error);
		}
	};

	// Calculating free trial end date
	const getFreeTrialEndDate = () => {
		let today = new Date();
		let date =
			today.getFullYear() +
			"-" +
			(today.getMonth() + 1) +
			"-" +
			today.getDate();
		const newDate = moment(date).add(world.free_trial_days, "d");
		const formatedDate = newDate.format("MMMM DD, YYYY");
		return formatedDate;
	};

	// Pushing event to moengage
	const pushMoengageEvent = () => {
		window.Moengage.track_event("WorldJoined", {
			world_title: world?.title,
			cost: world?.fee,
			child_first_name: props?.student?.first_name,
			renewal_date: world?.free_world === 1 ? "Never" : world?.free_trial === 1 ? getFreeTrialEndDate() : nextSubDate(),
			free_trial: world?.free_trial,
		});
	}

	useEffect(() => {
		fetchWorld();
		fetchWorldMembers();
		if (props.access_token === null || props.access_token === undefined) {
			setTimeout(() => {
				setShowGoogleLogin(true);
			}, 10000);
		}
		return () => {
			if (swal.getState().isOpen) {
				swal.close();
			}
			if (showGoogleLogin) {
				setShowGoogleLogin(false);
			}
		};
	}, [worldId]);

	const loginPopup = () => {
		if (props.access_token === null || props.access_token === undefined) {
			var swal_popup = document.createElement("div");
			ReactDOM.render(
				<React.StrictMode>
					<LoginPopup update_token={props.update_token} />
				</React.StrictMode>,
				swal_popup
			);
			swal({
				content: swal_popup,
				buttons: false,
				className: "swal-login",
				closeOnClickOutside: false,
			});
		}
	};

	const confPopup = () => {
		if (props.access_token === null || props.access_token === undefined) {
			loginPopup();
		} else {
			var conf_popup = document.createElement("div");
			ReactDOM.render(
				<React.StrictMode>
					<SubscriptionPopup
						title={world?.title}
						price={world?.fee}
						image={world?.image}
						freeWorld={world?.free_world}
						freeTrial={world?.free_trial}
						getFreeTrialEndDate={getFreeTrialEndDate}
					/>
				</React.StrictMode>,
				conf_popup
			);
			swal({
				content: conf_popup,
				buttons: false,
				className: "swal-payment-conf",
				closeOnClickOutside: false,
			});
			setIsMemberOfWorld(true);
		}
	};

	const subscribePopup = () => {
		if (props.access_token === null || props.access_token === undefined) {
			loginPopup();
		} else {
			var subscribe_popup = document.createElement("div");
			ReactDOM.render(
				<React.StrictMode>
					<SubscriptionPopupModal
						title={world?.title}
						price={world?.fee}
						image={world?.image}
						access_token={props.access_token}
					/>
				</React.StrictMode>,
				subscribe_popup
			);
			swal({
				content: subscribe_popup,
				buttons: false,
				className: "swal-qna",
				// closeOnClickOutside: true,
			});
		}
	};

	const SubscriptionPopup = (props) => {
		return (
			<div className="gopher-font">
				<img src={props.image} alt={props.title} />
				<h3 className="mt-2 px-4">
					Welcome to {props.title}🥳
				</h3>
				{props.freeTrial === 1 ? (
					<p className="mt-2 px-5">
						Enjoy free access till <span className="text-info">{props.getFreeTrialEndDate()}</span>{" "}. You'll be asked to pay {props.price}/month after.
					</p>
				) : (
					<p className="p-2">You'll be charged ₹ {props.price} per month.</p>
				)}
				<p className="mt-2 px-5">
					Let's take you inside! You can always enter all your joined worlds from your Dashboard.
				</p>
				<button className="px-3 py-1 my-3 text-white" onClick={closePopup}>
					Enter
				</button>
			</div>
		);
	};

	const AlreadySubPopup = () => {
		if (props.access_token === null || props.access_token === undefined) {
			loginPopup();
		} else {
			var already_sub_popup = document.createElement("div");
			ReactDOM.render(
				<React.StrictMode>
					<AlreadySubscriptionPopup
						image={world?.image}
						message={alreadySubscribedMsg}
						access_token={props.access_token}
					/>
				</React.StrictMode>,
				already_sub_popup
			);
			swal({
				content: already_sub_popup,
				buttons: false,
				className: "swal-payment-conf",
				closeOnClickOutside: TextTrackCueList,
			});
		}
	};

	const AlreadySubscriptionPopup = (props) => {
		const [subscribeLoading, setSubscribeLoading] = useState(false);

		const handleSubscribeTrialEnd = async () => {
			if (props.access_token === null || props.access_token === undefined) {
				loginPopup();
			} else {
				if (subscribeLoading) return
				setSubscribeLoading(true);
				let payment = 0;
				if (!world.free_world) {
					payment = 1;
				}
	
				let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/${worldId}/subscribe`;
	
				const body = JSON.stringify({
					payment: payment,
				});
				let requestOptions = {
					method: "POST",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						Authorization: "Bearer " + props.access_token,
					},
					body: body,
					redirect: "follow",
				};
	
				const response = await fetch(`${url}`, requestOptions);
				const result = await response.json();
	
				if (result.success) {
					if (result.data.redirect) {
						var redirect_url = result.data.url;
						window.location.replace(redirect_url);
					} else {
						confPopup();
					}
				} else {
					alreadySubscribedMsg = result?.message;
					AlreadySubPopup();
				}
				setSubscribeLoading(false);
			}
		};
		return (
			<div>
				<img src={props.image} alt={props.title} />
				<h3 className="my-3">
					{props.message ===
						"You've already subscribed for trial period before."
						? "Oh dear. Free trial is over 🥲 Proceed to Subscribe ?"
						: props.message}
				</h3>

				{props.message ===
					"You've already subscribed for trial period before." ? (
					<button
						className="pay-btn my-2"
						onClick={handleSubscribeTrialEnd}
						disabled={subscribeLoading}
					>
						{
							subscribeLoading ? (
								<Spinner animation="border" variant="light" size="sm" />
							) : (
								"Proceed to Subscribe"
							)
						}
					</button>
				) : (
					<button className="px-3 py-1 my-2" onClick={closePopup}>
						Enter
					</button>
				)}
			</div>
		);
	};

	const closePopup = () => {
		swal.close();
		history.push(`/worlds/${worldId}/activity`);
	};

	const SubscriptionPopupModal = (props) => {
		const [subscribeLoading, setSubscribeLoading] = useState(false);

		const handleSubscribe = async () => {
			if (props.access_token === null || props.access_token === undefined) {
				loginPopup();
			} else {
				if (subscribeLoading) return
				setSubscribeLoading(true);
				let payment = 0;
				if (!world.free_trial && !world.free_world) {
					payment = 1;
				}

				let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/${worldId}/subscribe`;

				const body = JSON.stringify({
					payment: payment,
				});
				let requestOptions = {
					method: "POST",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						Authorization: "Bearer " + props.access_token,
					},
					body: body,
					redirect: "follow",
				};

				const response = await fetch(`${url}`, requestOptions);
				const result = await response.json();

				if (result.success) {
					if (result.data.redirect) {
						var redirect_url = result.data.url;
						window.location.replace(redirect_url);
					} else {
						pushMoengageEvent();
						confPopup();
					}
				} else {
					alreadySubscribedMsg = result?.message;
					AlreadySubPopup();
				}
				setSubscribeLoading(false);
			}
		};

		return (
			<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 payment-popup no-padding">
				<div className="payment-popup-content">
					<div className="row no-margin first-section-payment">
						<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding">
							<img
								className="availability-cross-icon"
								src="/icons/cross.jpg"
								alt="availability-cross-icon"
								onClick={() => swal.close()}
								style={{
									cursor: "pointer",
									borderRadius: "50%",
								}}
							/>
							<img
								src={props.image}
								alt={props.title}
								className="payment-img"
							/>
						</div>
					</div>
					<div className="payment-popup-text-content gopher-font">
						<div className=" no-margin second-section-payment">
							<p className="mb-1">Subscribing to </p>
							<h3 style={{ fontSize: window.innerWidth > 500 ? "27px" : "22px" }}>{props.title}</h3>
						</div>

						{world.free_trial === 0 && world.free_world === 0 && (
							<div className="row no-margin third-section-payment my-3">
								<div className="col-6 no-padding">
									<h4>Subtotal</h4>
								</div>
								<div className="col-6 no-padding">
									<p>₹ {Number(props.price)}</p>
									<h6 style={{ fontWeight: "400", fontSize: "12px", textAlign: "right" }}>Tax Included</h6>
								</div>
							</div>
						)}

						{world.free_trial === 1 || world.free_world === 1 ? (
							<hr className="py-2" />
						) : null}

						<div className="row no-margin fourth-section-payment mb-3">
							<div className="col-6 no-padding">
								<h4>Total</h4>
							</div>
							<div className="col-6 no-padding">
								<p>
									₹{" "}
									{world.free_trial === 0 && world.free_world === 0
										? Number(props.price)
										: 0
									}

									<span style={{ fontSize: "12px", fontWeight: "300" }}>/month</span>
								</p>
							</div>
						</div>
						{world.free_trial === 1 && (
							<div className="row no-margin mb-2">
								<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding">
									<p className="">
										You won't be charged until <span className="text-info">{getFreeTrialEndDate()}</span>. After
										that, you will be asked to pay ₹ {world?.fee} to continue
										your subscription with {world.title}.
									</p>
								</div>
							</div>
						)}
						{world.free_trial === 0 && world.free_world === 0 && (
							<div className="row no-margin mb-3">
								<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding">
									<p className="">
										The subscription will automatically renew every 30 days. (next subscription on {" "}
										<span className="text-info">
											{nextSubDate()}
										</span>) {" "}
									</p>
								</div>
							</div>
						)}
						{world.free_world === 1 && (
							<div className="row no-margin mb-2">
								<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding">
									<p className="">
										This is a free World, you won't be charged ever!
									</p>
								</div>
							</div>
						)}
						<div className="row no-margin">
							<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 no-padding">
								<p className="cancellation-payment-popup">
									Cancellation/Refund Policy
								</p>
								<p className="cancellation-text-popup">
									This subscription can be cancelled anytime from <a href={`${window.location.origin
										}/past-recordings/worlds`} target="_blank">My Journey</a> page.
								</p>
							</div>
							<div className="clear"></div>

						</div>
						<div className="payment-popup-btn-div mt-3">
							<button
								className="pay-btn"
								onClick={handleSubscribe}
								disabled={subscribeLoading}
							>
								{
									subscribeLoading ? (
										<Spinner animation="border" variant="light" size="sm" />
									) : (
										world.free_trial === 1
											? "Join Now"
											: world.free_world === 1
												? "Join Now"
												: "Proceed to Subscribe"
									)
								}
							</button>
						</div>
						<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 t-c-accept no-padding">
							<p
								className="cancellation-text-popup muted-text"
								style={{ fontSize: "11px" }}
							>
								By clicking on the{" "}
								{world.free_trial === 1
									? '"Join"'
									: world.free_world === 1
										? '"Join"'
										: '"Proceed to Subscribe"'}{" "}
								button, you agree to the{" "}
								<a
									href="https://drive.google.com/file/d/1Fh3r8YVep2SdXenw9xmL1r4pHokqe4AK/view"
									target="_blank"
									rel="noopener noreferrer"
								>
									Cancellation and Refunds Policy
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	/* 
	  Note: This particular function triggers when subscription query is shown,
	  it take the subscription query and pass it to backend to keep track of the subscription
	  and get popup once result is success.
	  */
	const confirmSubscription = async () => {
		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/${worldId}/subscribe/confirm/${subscription}`;
		let requestOptions = {
			method: "GET",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
				Authorization: "Bearer " + props.access_token,
			},
		};

		const response = await fetch(`${url}`, requestOptions);
		const result = await response.json();
		if (result.success) {
			pushMoengageEvent();
			confPopup();
		}
	};

	/*
		  Note: When subscription query is showing on the URL,
		  it fetches the subscription flag and show the
		  Thank you message present in the popup
	  */

	if (world && subscription) {
		confirmSubscription();
	}

	if (world) {
		document.title = `${world?.title} - ${world?.uuid?.user?.first_name} | HelloWorlds`;
	}

	const handlesneakPeek = (e) => {
		e.preventDefault();
		if (props.access_token === null || props.access_token === undefined) {
			loginPopup();
		} else {
			history.push(`/worlds/${worldId}/activity`);
		}
	}

	// Show scroll to top button
	window.onscroll = () => {
		window.scrollY > 300 ? setShowScrollBtn(true) : setShowScrollBtn(false);
		window.scrollY > 200 ? setShowSubHeader(true) : setShowSubHeader(false);
	};

	return (
		<>
			<div className="d-md-block d-none gopher-font">
				<Header
					avatar_url={props.avatar_url}
					access_token={props.access_token}
					current_route="worlds"
					update_token={props.update_token}
				/>
			</div>
			{showGoogleLoginSuggestion && showGoogleLogin && (
				window.innerWidth > 768 ? (
					<GoogleLoginSuggestion setShowGoogleLoginSuggestion={setShowGoogleLoginSuggestion} />
				) : null
			)}
			<Container
				fluid
				className={`${styles.container}`}
				style={{
					background: "var(--background_primary)",
				}}
			>
				{
					showSubHeader ? (
						<SubHeaderWorlds back="Worlds" backUrl="/worlds" mentor={world?.uuid?.user} world={world} />
					) : (
						<SubHeader back="Worlds" backUrl="/worlds" />
					)
				}

				<div className="d-md-block d-none gopher-font ps-2">
					{/* <p className="club-type-text mt-md-3">
						<i className="fa fa-globe" aria-hidden="true"></i> Club
					</p> */}
					<h1 className="mt-0 fw-bold">{world?.title}</h1>
					<p className="by-new-design fw-bold text-info" style={{ fontSize: "18px" }}>
						<Link to={`/mentor/${world?.uuid?.user?.slug}`}>
							<LazyLoad
								src={world?.uuid?.user?.avatar_url}
								alt="User Avatar"
								classes="mentor-icon-detail-page"
							/>
						</Link>{" "}
						By{" "}
						<Link to={`/mentor/${world?.uuid?.user?.slug}`} className="text-info">
							{`${world?.uuid?.user?.first_name} ${world?.uuid?.user?.last_name}`}
						</Link>
					</p>
					<div className="no-padding">
						<RWebShare
							data={{
								text:
									"Hey, Checkout this club on HelloWorlds: " + world?.title,
								url:
									"https://app.helloworlds.co/worlds/" +
									worldId,
								title: world?.title,
							}}
							onClick={() => console.log("shared successfully!")}
						>
							<div
								className="share-div"
								style={{
									position: "relative",
									bottom: "52px",
									right: "95px"
								}}
							>
								<LazyLoad
									src="/icons/share.png"
									alt="Share Icon"
									classes="share-icon"
									style={{
										position: "relative",
										top: "-1px",
									}}
								/>
								<span>Share</span>
							</div>
						</RWebShare>
					</div>
				</div>

				<Row>
					<Col xs={12} md={9} className="mt-md-2 pe-md-3 gopher-font">
						<Row className="position-relative">
							{!playVideo ? (
								<LazyLoad
									src={
										world?.image
											? world?.image
											: "/images/default_webinar_img.png"
									}
									alt="banner"
									classes={styles.banner}
								/>
							) : (
								world?.video_url?.length > 5 && (
									<>
										<iframe
											src={world?.video_url}
											title="YouTube video player"
											allowFullScreen
											className={styles.video}
										/>
										<img
											src="/icons/cross.png"
											alt="cross"
											className={styles.crossIcons}
											onClick={() => setPlayVideo(false)}
										/>
									</>
								)
							)}

							{!playVideo && world?.video_url?.length > 5 && (
								<>
									<img
										src="/icons/play.png"
										alt="play"
										className={styles.videoIcon}
										onClick={() => setPlayVideo(true)}
									/>
								</>
							)}
						</Row>

						<div className="d-md-none d-block mt-0 px-0">
							{/* <p className="club-type-text mt-md-3 pt-2">
								<i className="fa fa-globe" aria-hidden="true"></i> Club
							</p> */}
							<h1 style={{ marginTop: window.innerWidth < 600 ? "-20px" : "" }}>{world?.title}</h1>
							<div className="by-new-design mb-5 fw-bold text-info" style={{ fontSize: "18px" }}>
								<Link to={`/mentor/${world?.uuid?.user?.slug}`}>
									<LazyLoad
										src={world?.uuid?.user?.avatar_url}
										alt="User Avatar"
										classes="mentor-icon-detail-page"
									/>
								</Link>{" "}
								By{" "}
								<Link to={`/mentor/${world?.uuid?.user?.slug}`} className="text-info">
									{`${world?.uuid?.user?.first_name} ${world?.uuid?.user?.last_name}`}
								</Link>
								<div className="no-padding" style={{ flex: "1" }}>
									<RWebShare
										data={{
											text:
												"Hey, Checkout this club on HelloWorlds: " + world?.title,
											url:
												"https://app.helloworlds.co/worlds/" +
												worldId,
											title: world?.title,
										}}
										onClick={() => console.log("shared successfully!")}
									>
										<div
											className="share-div">
											<LazyLoad
												src="/icons/share.png"
												alt="Share Icon"
												classes="share-icon"
											/>
											<span>Share</span>
										</div>
									</RWebShare>
								</div>
							</div>

						</div>

						<Row as="p" className="mb-4 px-3" style={{ marginTop: window.innerWidth > 768 ? "-60px" : "-35px", fontSize: "18px" }}>
							{world?.tagline
								?.replace(/(<([^>]+)>)/gi, "")
								.replace(/(&([^>]+);)/gi, "")}
						</Row>

						<Row
							as="ul"
							className={styles.details + " my-5"}
							style={
								dark
									? {
										boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
									}
									: {
										boxShadow: "#d9d9d9 0px 2px 10px",
									}
							}
						>
							<li>
								<p>Language</p>
								<p>{world?.language.language}</p>
							</li>
							<li>
								<p>Suitable for</p>
								<p>{`Grade ${world?.grades[0].grade}-${world?.grades[world?.grades.length - 1].grade
									}`}</p>
							</li>
							<li>
								<p>Subjects</p>
								{
									world?.primary_subject?.id === world?.secondary_subject?.id ? (
										<p>{`${world?.primary_subject.name}`}</p>
									) : (
										<p>{`${world?.primary_subject.name}, ${world?.secondary_subject.name}`}</p>
									)
								}
							</li>
							<li>
								<p>Upto</p>
								<p>{world?.group_size} Participants</p>
							</li>
							<li>
								<p>Time Commitment</p>
								<p>{world?.time_commitment}/Week</p>
							</li>
						</Row>

						{/* {
							!isMemberOfWorld && window.innerWidth < 768 && (
								<div className="pb-2 ps-1">
									<Link
										to={`/worlds/${worldId}/activity`}
										onClick={handlesneakPeek}
										className="btn-boo text-decoration-none"
										style={{
											color: "var(--fontColor-primary)",
										}}
									>
										Get a sneak peek
									</Link>
								</div>
							)
						} */}

						<Row className="m-0 mt-3">
							<div className={styles.clubContent}>
								<ul className="mb-5">
									<li
										onClick={() => {
											executeScroll(detailsRef);
										}}
									>
										Details
									</li>
									<li
										onClick={() => {
											executeScroll(requirementsRef);
										}}
									>
										Requirements
									</li>
									<li
										onClick={() => {
											executeScroll(mentorRef);
										}}
									>
										Mentor
									</li>
								</ul>
							</div>

							<div ref={detailsRef} className="pt-4">
								<h3>Details</h3>
								<ClubContent
									content={world?.description}
									img="no-img"
									url="no-url"
								/>
								<SectionContent
									title="What you'll learn"
									content={world?.outcome}
									img="no-img"
									url="no-url"
								/>
							</div>

							<div ref={requirementsRef} style={{ marginTop: "-8px" }}>
								<SectionContent
									title="Requirements"
									content={world?.requirement}
									img="no-img"
									url="no-url"
								/>
							</div>

							<div ref={mentorRef}>
								<SectionContent
									title={`Meet your mentor, ${world?.uuid?.user?.first_name} ${world?.uuid?.user?.last_name}`}
									img={world?.uuid?.user?.avatar_url}
									url={`/mentor/${world?.uuid?.user?.slug}`}
									content={world?.uuid?.user?.about_me}
								/>
							</div>
						</Row>

					</Col>
					<Col
						xs={12}
						md={3}
						className={`mt-2 ps-md-3 d-none d-md-block gopher-font`}
						style={{ position: "sticky", top: "100px" }}
					>
						<div
							className={`py-3 px-3 ${styles["pricing-box"]} sticky-price-box`}
							style={
								dark
									? {
										boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px"
									}
									: {
										boxShadow: "#d9d9d9 0px 2px 10px",
									}
							}
						>
							<div className={styles.price}>
								{world?.free_world === 1 ? (
									<span>
										Free forever
										<small
											className="fs-2 mx-1"
											style={{ position: "relative", bottom: "2px" }}
										>
											🙌
										</small>
									</span>
								) : world?.free_trial === 1 ? (
									<>
										<p className="fs-3 mb-0">
											<span>₹ {world?.fee} </span>
											<sub style={{ position: "relative", bottom: "0" }}>
												/ month
											</sub>
										</p>
										<p className="fw-normal pt-1 fs-5 fw-bold">
											{world?.free_trial_days} Days Free Trial{" "}
											<OverlayTrigger
												placement="left"
												delay={{ show: 50, hide: 300 }}
												overlay={
													<div
														style={{
															backgroundColor: "var(--background_primary)",
															padding: "10px 20px 10px 0",
															color: "var(fontColor-primary)",
															borderRadius: "10px",
															width: "280px",
															boxShadow: "var(--shadow_primary)"
															// textAlign: "justify",
														}}
														id="button-tooltip"
													>
														<ul>
															<li>
																Complete access to this world is offered during the free trial. No payment details required.
															</li>
															<li>
																After the trial, you'll be asked to pay the monthly subscription fee to continue the access.
															</li>
															<li>
																Whether or not a free trial is offered (and the trial duration) is decided by the mentor. So, you'll find other worlds which require upfront payment and many others that start with a free trial.
															</li>
														</ul>
													</div>
												}
											>
												<img
													src="/icons/circle-question-regular.svg"
													alt="emoji"
													className="ms-1 text-white"
													style={
														dark
															? {
																width: "15px",
																filter: "invert(1)",
																position: "relative",
																bottom: "2px",
																right: "1px",
																cursor: "pointer",
															}
															: {
																width: "14px",
																position: "relative",
																bottom: "2px",
																right: "1px",
																cursor: "pointer",
															}
													}
												/>
											</OverlayTrigger>
										</p>
									</>
								) : (
									<p className="fs-3">
										<span>₹ {world?.fee} </span>
										<sub style={{ position: "relative", bottom: "0" }}>
											/ month
										</sub>
									</p>
								)}
							</div>

							<div className="thing-to-do">
								<LazyLoad
									src="/icons/tick.png"
									alt="tick icon"
									style={{
										height: "25px",
										width: "25px",
									}}
								/>
								<span>Join anytime. Cancel anytime.</span>
							</div>
							<div className="thing-to-do">
								<LazyLoad
									src="/icons/tick.png"
									alt="tick icon"
									style={{
										height: "25px",
										width: "25px",
									}}
								/>
								<span>Mingle with other like-minded kids.</span>
							</div>
							<div className="thing-to-do">
								<LazyLoad
									src="/icons/tick.png"
									alt="tick icon"
									style={{
										height: "25px",
										width: "25px",
									}}
								/>
								<span>Renews every month.</span>
							</div>
							{isMemberOfWorld ? (
								<>
									<p
										className="text-info fw-bold pt-4 mb-0 text-center"
										style={{ fontSize: "15px" }}
									>
										You are already subscribed!
										<small
											className=" mx-0"
											style={{ position: "relative", bottom: "1px" }}
										>
											😎
										</small>
									</p>
									<div
										className="d-flex"
										style={{ marginTop: "-10px" }}
									>
										<button
											type="button"
											className={styles.subscribe}
											style={dark ? {} : { color: "white" }}
											onClick={() => {
												props.history.push(`/worlds/${world?.id}/activity`);
											}}
										>
											Enter
										</button>
									</div>
								</>
							) : (
								<>
									<button
										type="button"
										className={styles.subscribe + " text-white mb-0"}
										style={{ height: "46px" }}
										onClick={subscribePopup}
									>
										Join Now
									</button>
									{/* <div className="ps-2 py-2">
										<Link
											to={`/worlds/${world?.id}/activity`}
											onClick={handlesneakPeek}
										>
											Get a sneak peek
										</Link>
									</div> */}
								</>
							)}
						</div>
					</Col>
				</Row>

				<Container>
					<div className={styles.howWorldWorks + " gopher-font"}>
						<h3 className="text-center">
							How does a "World" work?
						</h3>
						<Row className="mt-lg-5">
							{
								window.innerWidth < 768 ? (
									<Col xs={12} md={6} className="mb-3 d-flex justify-content-center">
										<img
											src="/images/worlds.png"
											className={styles.howWorldWorksImage}
											alt={world?.title}
										/>
									</Col>
								) : null
							}

							<Col xs={12} md={6}>
								<div className={styles.howWorldWorksContent + " mb-5"}>
									<h5>
										<span>
											<i className="fa fa-users"></i>
										</span>
										A club, but online
									</h5>
									<p>
										Mentors/Influencers from your favourite topics create a "World", an online community where you share, create and learn with like-minded peers.
									</p>
								</div>
								<div className={styles.howWorldWorksContent + " mb-5"}>
									<h5>
										<span>
											<i className="fa fa-universal-access"></i>
										</span>
										Access anytime
									</h5>
									<p>
										You can join or exit whenever you like. These worlds are either free or run for a small monthly fee by your mentors.
									</p>
								</div>
								<div className={styles.howWorldWorksContent + " mb-2"}>
									<h5>
										<span>
											<i className="fa fa-comments"></i>
										</span>
										Mingle, learn, chat
									</h5>
									<p>
										Video-chats, discussions, challenges, useful resources and media sharing, group chats, it's all there! Join your first World, and experience the magic.
									</p>
								</div>
							</Col>

							{
								window.innerWidth > 768 ? (
									<Col xs={12} md={6} className="d-flex justify-content-center">
										<img
											src="/images/worlds.png"
											className={styles.howWorldWorksImage}
											alt={world?.title}
										/>
									</Col>
								) : null
							}
						</Row>

						<div className="mt-3 mb-3">
							{isMemberOfWorld ? (
								<button
									type="button"
									className={styles.subscribe + " m-auto d-block"}
									style={dark ? {} : { color: "white" }}
									onClick={() => {
										props.history.push(`/worlds/${world?.id}/activity`);
									}}
								>
									Enter
								</button>
							) : (
								<>
									<button
										type="button"
										className={styles.subscribe + " m-auto d-block text-white"}
										style={{ height: "46px" }}
										onClick={subscribePopup}
									>
										Join Now
									</button>
								</>
							)}
						</div>
					</div>
				</Container>

				<div className={styles.recommendations}>
					<h3>Explore More Worlds</h3>
					<Recommendations access_token={props.access_token} currentWorld={worldId} />
				</div>
			</Container >

			<div className={`${styles.price} d-md-none d-flex m-0`}>
				<div className="m-0 p-0">
					{world?.free_world === 1 ? (
						<span className="fs-5">
							Free forever
							<small
								className="fs-4 mx-1"
								style={{ position: "relative", bottom: "2px" }}
							>
								🙌
							</small>
						</span>
					) : world?.free_trial === 1 ? (
						<>

							<p className="fs-4 mb-0">
								<span className="fs-4">₹ {world?.fee} </span>
								<sub style={{ position: "relative", bottom: "0" }}>/ month</sub>
							</p>
							<p className="fw-normal pt-1 fs-5 fw-bold">
								{world?.free_trial_days} Days Free Trial{" "}
								<OverlayTrigger
									placement="top"
									delay={{ show: 10, hide: 50 }}
									overlay={
										<div
											style={{
												backgroundColor: "var(--background_primary)",
												padding: "10px 20px 10px 0",
												color: "var(fontColor-primary)",
												borderRadius: "10px",
												width: "280px",
												boxShadow: "var(--shadow_primary)",
												zIndex: 100
											}}
											id="button-tooltip"
										>
											<ul>
												<li>
													Complete access to this world is offered during the free trial. No payment details required.
												</li>
												<li>
													After the trial, you'll be asked to pay the monthly subscription fee to continue the access.
												</li>
												<li>
													Whether or not a free trial is offered (and the trial duration) is decided by the mentor. So, you'll find other worlds which require upfront payment and many others that start with a free trial.
												</li>
											</ul>
										</div>
									}
								>
									<img
										src="/icons/circle-question-regular.svg"
										alt="emoji"
										className="ms-1 text-white"
										style={
											dark
												? {
													width: "15px",
													filter: "invert(1)",
													position: "relative",
													bottom: "2px",
													right: "1px",
													cursor: "pointer",
												}
												: {
													width: "14px",
													position: "relative",
													bottom: "2px",
													right: "1px",
													cursor: "pointer",
												}
										}
									/>
								</OverlayTrigger>
							</p>
						</>
					) : (
						<div className="fs-4">
							<span className="fs-4">₹ {world?.fee} </span>
							<sub style={{ position: "relative", bottom: "0" }}>/ month</sub>
						</div>

					)}
				</div>

				{isMemberOfWorld ? (
					<>
						<div>
							<button
								type="button"
								className={styles.subscribe}
								style={dark ? {} : { color: "white" }}
								onClick={() => {
									props.history.push(`/worlds/${world?.id}/activity`);
								}}
							>
								Enter
							</button>
						</div>
					</>
				) : (
					<>
						<div className="text-center">
							<button
								type="button"
								className={styles.subscribe + " text-white"}
								style={{ height: "40px" }}
								onClick={subscribePopup}
							>
								Join Now
							</button>
						</div>
					</>
				)}
			</div>

			{
				showScrollBtn && (
					<div className={styles.scrollToTop}>
						<button
							type="button"
							className="btn text-white border-0"
							onClick={() => {
								window.scrollTo({
									top: 0,
									behavior: "smooth",
								});
							}}
						>
							<i className="fa fa-arrow-up" />
						</button>
					</div>
				)
			}
			<Footer />
		</>
	);
};

const Title = (props) => {
	let img_class = "hide-it";
	if (props.img !== "no-img") {
		img_class = "mentor-big-detail-page";
	}
	return (
		<h3 className="world-title webinar-title">
			{props.img !== "no-img" ? (
				<Link to={props.url}>
					<LazyLoad src={props.img} alt="User Avatar" classes={img_class} />
				</Link>
			) : (
				""
			)}{" "}
			{props.title}
		</h3>
	);
};

export default SingleClub;
