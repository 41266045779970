const palleteLight = () => {
	document.querySelector(":root").style.backgroundColor = "#f4f4f4";
	document
		.querySelector(":root")
		.style.setProperty("--background_primary", "#ffffff");
	document
		.querySelector(":root")
		.style.setProperty("--background_secondary", "#f4f4f4");
	document
		.querySelector(":root")
		.style.setProperty("--background_tertiary", "#e8e9eb");
	document
		.querySelector(":root")
		.style.setProperty("--fontColor-primary", "#222222");
	document
		.querySelector(":root")
		.style.setProperty("--fontColor-secondary", "#717171");
	document
		.querySelector(":root")
		.style.setProperty("--fontColor-eo", "rgba(0, 0, 0, 0.6)");
	document
		.querySelector(":root")
		.style.setProperty("--shadow_primary", "rgb(217, 217, 217) 0px 2px 10px");
};

export default palleteLight;
