import { useState, useEffect } from "react";
import styles from "./UpcomingLiveRooms.module.css";
import { Link } from "react-router-dom";
import moment from "moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LazyLoad from "../Commons/LazyLoad";
import { Spinner } from "react-bootstrap";

const UpcomingLiveRooms = ({ key, student_id, access_token, dark }) => {
	const [upcoming, setUpcoming] = useState([]);
	const [loading, setLoading] = useState(true);
	const [redirectLoading, setRedirectLoading] = useState(false);

	/**
 * Getting Zoom link and redirecting to zoom link
 * @param {number} session_id
 */
	const redirectToZoom = (id) => {
		if (redirectLoading) return;
		setRedirectLoading(true);
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + access_token);
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({ random: "random" });

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		var url = `${process.env.REACT_APP_BASE_URL}/rest/students/webinars/${id}}/register`;

		fetch(url, requestOptions)
			.then(function (response) {
				let status = response.status;
				let json_response = response.json();
				if (status !== 200) {
					if (status === 401) {
						throw new Error("Your session has expired. Please login again.");
					} else {
						throw new Error("Something went wrong. Please contact support.");
					}
				} else {
					return json_response;
				}
			})
			.then((result) => {
				setRedirectLoading(false);
				if (result.success === true) {
					window.open(result.data.zoom_link, "_blank");
				}
			});
	};

	useEffect(() => {
		if (student_id !== null) {
			setLoading(true);

			let myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + access_token);

			let requestOptions = {
				method: "GET",
				headers: myHeaders,
				redirect: "follow",
			};

			let url = `${process.env.REACT_APP_BASE_URL}/rest/students/webinars/dashboard`;

			fetch(url, requestOptions)
				.then((res) => res.json())
				.then((result) => {
					setUpcoming(result.data.results);
					setLoading(false);
				})
				.catch((err) => {
					console.log(err);
					setLoading(false);
				});
		}
	}, [access_token, student_id]);

	const LoadingSkeleton = () => {
		return (
			<div className={styles.eventCard}>
				<div className={styles.left}>
					<div className={styles.date}>
						<p>
							<Skeleton width={20} />
						</p>
						<p>
							<Skeleton width={20} />
						</p>
					</div>
					<div className={styles.content}>
						<h6>
							<Skeleton width={200} />
						</h6>
						<div className={styles.features}>
							<div className={styles.feature}>
								<Skeleton width={50} />
							</div>

							<div className={styles.feature}>
								<Skeleton width={50} />
							</div>

							<div className={styles.feature}>
								<Skeleton width={50} />
							</div>

						</div>
					</div>
				</div>

				<div className={styles.right}>
					<div className={styles.date}>
						<p>
							<Skeleton width={20} />
						</p>
						<p>
							<Skeleton width={20} />
						</p>
					</div>
					<button className="snippet-dashboard-attend">
						<Skeleton width={50} />
					</button>
				</div>
			</div>
		);
	};

	return (
		<div
			className="upcoming-exp-dashboard-div"
			style={
				dark
					? {
						boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
						background: "var(--background_primary)",
					}
					: {
						boxShadow: "#d9d9d9 0px 2px 10px",
						background: "var(--background_primary)",
					}
			}
		>
			<h2 className={styles.heading}>Your Upcoming Live Rooms</h2>
			{loading ? (
				<SkeletonTheme baseColor="gray" highlightColor="white">
					<Skeleton wrapper={LoadingSkeleton} count={2} />
				</SkeletonTheme>
			) : (
				<>
					{upcoming.map((room) => {
						return (
							<div className={styles.eventCard} key={room?.session_id}>
								<div className={styles.left}>
									<div className={styles.date}>
										<p>
											{moment(room?.start_time_ts).format("DD")}
										</p>
										<p>
											{moment(room.start_time_ts).format("MMM")}
										</p>
									</div>
									<div className={styles.content}>
										<div className={styles.title}>
											<h6>
												{room?.session_title}
											</h6>
											<p>Hosted in {room?.world_name}</p>
										</div>
										<div className={styles.features}>
											<div className={styles.feature}>
												{moment(room.start_time_ts).format("h:mm A")}
											</div>

											<div className={styles.feature}>
												{moment(room?.end_time_ts).diff(moment(room?.start_time_ts), "minutes")} mins
											</div>

											<div className={styles.feature}>
												{room?.room_type
													? room?.room_type
													: "Workshop"}
											</div>
										</div>
									</div>
								</div>

								<div className={styles.right}>
									<div className={styles.date}>
										<p>
											{moment(room.start_time_ts).format("DD")}
										</p>
										<p>
											{moment(room.start_time_ts).format("MMM")}
										</p>
									</div>
									<button
										className="snippet-dashboard-attend"
										disabled={redirectLoading}
										onClick={() => redirectToZoom(room?.session_id)}
									>
										{
											redirectLoading ? (
												<Spinner animation="border" size="sm" />
											) : (
												"Join"
											)
										}
									</button>
								</div>
							</div>
						);
					})}

					{!upcoming.length && (
						<div className="no-upcoming-dashboard">
							<LazyLoad
								src="/icons/calendar.png"
								classes="calendar-dashboard"
								alt="calendar-dashboard"
							/>
							<p className="no-bookings-yet">
								You don't have any upcoming sessions from your clubs.{" "}
							</p>
							<button className="dashboard-browse-btn">
								{/* <Link to="/learn">Browse</Link> */}
								<Link to="/worlds">Browse</Link>
							</button>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default UpcomingLiveRooms;
