import style from "./Worlds/Activity/Feed.module.css";
import LazyLoad from "./Commons/LazyLoad";
import styles from "./Worlds/Activity/ActivityCard.module.css";
import moment from "moment/moment";
import { Col, Row } from "react-bootstrap";
import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import ImageSlider from "./Worlds/ImageSlider";


const ActivityModalFeed = (props) => {
    const history = useHistory();
    const activity = props.activity;
    const access_token = props.access_token;
    const student_uuid = localStorage.getItem("student_uuid")

    //Checking if the post is already liked by the user
    const [isLiked, setIsLiked] = useState(activity?.latest_reactions?.like?.filter((like) => like?.user_id === student_uuid)[0]?.user_id)
    const [likeCount, setLikeCount] = useState(activity?.reaction_counts?.like || 0)
    const [likeLoading, setLikeLoading] = useState(false)

    const [comment, setComment] = useState(activity?.latest_reactions?.comment || [])
    const [commenting, setCommenting] = useState(false)
    const [commentErr, setCommentErr] = useState(false)

    let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/`;
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access_token);

    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
    };
    const commentRef = useRef(null);
    const [comments, setComments] = useState(2);

    // Ading like to the post
    const handleLikeOnPost = async (worldId, activityId) => {
        try {
            setLikeLoading(true)
            setIsLiked(true)
            setLikeCount(likeCount + 1)
            const response = await fetch(`${url}${worldId}/add-like/${activityId}`, requestOptions);
            const data = await response.json();
            if (data.success) {
                setLikeLoading(false)
            }
            else {
                setIsLiked(false)
                setLikeLoading(false)
                setLikeCount(likeCount - 1)
            }
        } catch (error) {
            console.error("Like Error : ", error);
        }
    }

    // Removing like or comment from the post
    const handleDislikeOnPost = async (worldId, reactionId) => {
        try {
            setLikeLoading(true)
            setIsLiked(false)
            setLikeCount(likeCount - 1)
            const response = await fetch(`${url}${worldId}/remove-like-comment/${reactionId}`, requestOptions);
            const data = await response.json();
            if (data.success) {
                setLikeLoading(false)
            } else {
                setIsLiked(true)
                setLikeLoading(false)
                setLikeCount(likeCount + 1)
            }
        } catch (error) {
            console.error("Dislike Error : ", error);
        }
    }

    // Adding comment to the post
    const handleCommentOnPost = async (worldId, activityId) => {
        try {
            const commentData = commentRef.current.value;
            if (!commentData && commentData === "") {
                return
            }
            commentRef.current.value = "";
            setCommenting(true)
            setCommentErr(false)
            const newCommentData = [
                {
                    data: {
                        text: commentData
                    },
                    user: {
                        data: {
                            avatar_url: props.student?.avatar_url,
                            name: props.student?.first_name + " " + props.student?.last_name
                        }
                    }
                }
            ]
            setComment(newCommentData.concat(comment))
            const response = await fetch(`${url}${worldId}/add-comment/${activityId}`, {
                method: "POST",
                redirect: "follow",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + access_token,
                },
                body: JSON.stringify({
                    text: commentData
                })
            })
            const data = await response.json();
            if (data.success === true) {
                setCommentErr(false)
                setCommenting(false)
                commentRef.current.value = "";
            } else {
                setCommentErr(true)
            }

        } catch (error) {
            console.error("Comment Error : ", error);
        }
    }

    //First letter capitalize
    const changeNameToLowerCase = (name) => {
        if (!name || name === "" || name === undefined) return
        const fullNameCaps = name?.split(/\s+/)
        const fullName = fullNameCaps.map((name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase())
        return fullName.join(' ')
    }

    // changing date and time format
    const changeDateTime = (dateTime) => {
        const newDateTime = moment.utc(dateTime).local().fromNow();
        const newDate = newDateTime.split(" ");
        if (newDate[0] === "a" || newDate[0] === "an") {
            const time = "1" + newDate[1].slice(0, 1) + " ago"
            if (time === "1f ago") {
                return "Just now"
            } else {
                return time
            }
        }

        const newTime = newDate[0] + newDate[1].slice(0, 1);
        return newTime + " ago";
    }

    return (
        <div className="mb-4 gopher-font">
            <Col
                className={style.activity}
                style={
                    props.dark
                        ? {
                            boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
                            background: "var(--background_primary)",
                        }
                        : {
                            boxShadow: "#d9d9d9 0px 2px 10px",
                            background: "var(--background_primary)",
                        }
                }
            >
                <Row className={style["individual-activity"]}>
                    <div>
                        <div className={styles.author}
                            onClick={() => {
                                history.push(`/worlds/${activity?.world?.id}/activity`)
                            }}>
                            <div className={styles["author-details"]}>
                                <LazyLoad
                                    src={
                                        activity?.actor_data?.avatar_url ? (
                                            activity?.actor_data?.avatar_url
                                        ) : (
                                            "/student.png"
                                        )
                                    }
                                />
                                <div className="d-flex flex-row align-items-center" style={{ position: "relative", bottom: "6px" }}>
                                    <h6 className="me-0">
                                        {
                                            activity?.actor_data?.name && (
                                                changeNameToLowerCase(activity?.actor_data.name)
                                            )
                                        }
                                    </h6>
                                    <p className={styles.time}>
                                        {changeDateTime(activity.time)}
                                    </p>

                                </div>
                            </div>
                            <p className={styles.clubName} style={{ left: window.innerWidth > 600 ? "68px" : "57px", bottom: "30px" }}>
                                {
                                    window.innerWidth > 500 ? activity?.world?.name : activity?.world?.name?.length > 25 ? activity?.world?.name?.slice(0, 25) + "..." : activity?.world?.name
                                }
                            </p>

                        </div>
                        <p
                            className="mx-2 mb-2"
                            style={{ fontSize: "17px" }}
                        >
                            {activity.text}
                        </p>
                        {
                            activity.tag && activity.tag.length > 0 && (
                                <p className="my-0 mb-2 mx-2">
                                    {
                                        activity.tag.map((tag, index) => {
                                            return (
                                                <span key={index}>
                                                    {
                                                        tag?.name + " "
                                                    }
                                                </span>
                                            )
                                        })
                                    }
                                </p>
                            )
                        }

                        {
                            activity?.image ? (
                                <div className={styles["activity-image"]} style={{ width: "100%" }}>
                                    {
                                        typeof activity?.image === 'string' ? (
                                            activity?.image?.includes("youtube") ? (
                                                <iframe
                                                    src={activity?.image}
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                />
                                            ) : (
                                                <ImageSlider images={[activity?.image]} actorName={activity.actor_data?.name} />
                                            )
                                        ) : (
                                            Array.isArray(activity?.image) && activity?.image.length > 0 ? (
                                                <ImageSlider images={activity?.image} actorName={activity.actor_data?.name} />
                                            ) : null
                                        )
                                    }
                                </div>
                            ) : null
                        }

                        <div className={styles.stats}>
                            <p style={{ cursor: "pointer" }}>
                                <button
                                    className="text-secondary"
                                    style={{
                                        background: "transparent",
                                    }}
                                    onClick={
                                        () => {
                                            if (isLiked) {
                                                if (likeLoading) {
                                                    return
                                                }
                                                handleDislikeOnPost(activity?.world?.id, activity?.latest_reactions?.like?.filter((like) => like.user_id === student_uuid)[0].id)
                                            } else {
                                                if (likeLoading) {
                                                    return
                                                }
                                                handleLikeOnPost(activity?.world?.id, activity.id)
                                            }
                                        }
                                    }
                                >
                                    <i
                                        className={`fa fa-thumbs-up me-1 ${isLiked ? "text-danger" : ""} `}
                                        aria-hidden="true"
                                    >
                                    </i>
                                    {likeCount}
                                </button>
                            </p>

                        </div>
                        <div className={styles.comments}>
                            {
                                commenting ? (
                                    <div className={styles["self-comment"]} style={{ opacity: "0.5" }}>
                                        <LazyLoad
                                            src={
                                                props.student.avatar_url ? (
                                                    props.student.avatar_url
                                                ) : (
                                                    "./student.png"
                                                )
                                            }
                                        />
                                        <form
                                            method="POST"
                                            className="w-100"
                                            onSubmit={(e) => {
                                                e.preventDefault()
                                                handleCommentOnPost(activity?.world?.id, activity.id)
                                            }}
                                        >
                                            <div>
                                                <input
                                                    type="text"
                                                    placeholder="Leave a comment"
                                                    ref={commentRef}
                                                    disabled={commenting}
                                                />
                                                {
                                                    window.innerWidth < 500 && (
                                                        <button
                                                            disabled={commenting}
                                                            type='submit'
                                                            style={
                                                                {
                                                                    background: "transparent",
                                                                    position: "relative",
                                                                    bottom: "34px",
                                                                    left: "195px"
                                                                }
                                                            }
                                                        >
                                                            <i
                                                                className="fa fa-paper-plane"
                                                                aria-hidden="true">

                                                            </i>
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        </form>
                                    </div>
                                ) : (
                                    <div className={styles["self-comment"]}>
                                        <LazyLoad
                                            src={
                                                props.student.avatar_url ? (
                                                    props.student.avatar_url
                                                ) : (
                                                    "./student.png"
                                                )
                                            }
                                        />
                                        <form
                                            method="POST"
                                            className="w-100"
                                            onSubmit={(e) => {
                                                e.preventDefault()
                                                handleCommentOnPost(activity?.world?.id, activity.id)
                                            }}
                                        >
                                            <div>
                                                <input
                                                    type="text"
                                                    placeholder="Leave a comment"
                                                    ref={commentRef}
                                                    disabled={commenting}
                                                />
                                                {
                                                    window.innerWidth < 500 && (
                                                        <button
                                                            disabled={commenting}
                                                            type='submit'
                                                            style={
                                                                {
                                                                    background: "transparent",
                                                                    position: "relative",
                                                                    bottom: "34px",
                                                                    left: "195px"
                                                                }
                                                            }
                                                        >
                                                            <i
                                                                className="fa fa-paper-plane"
                                                                aria-hidden="true">
                                                            </i>
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        </form>
                                    </div>
                                )
                            }

                            {
                                commentErr && (
                                    <p
                                        style={{ color: "red", margin: "10px 0 12px 10px" }}
                                    >
                                        Something went wrong, please try again
                                    </p>
                                )
                            }

                            {
                                comment?.length > 0 &&
                                    comment?.length <= comments ? (
                                    comment?.map((item, index) => (
                                        commenting ? (
                                            <div key={index} className={styles["other-comments"]} style={{ opacity: "0.5" }}>
                                                <LazyLoad
                                                    src={
                                                        item?.user?.data?.avatar_url ? (
                                                            item?.user?.data?.avatar_url
                                                        ) : (
                                                            "/student.png"
                                                        )
                                                    }
                                                />
                                                <p>
                                                    <strong>{changeNameToLowerCase(item?.user?.data?.name)}</strong> {item?.data?.text}
                                                </p>
                                            </div>
                                        ) : (
                                            <div key={index} className={styles["other-comments"]}>
                                                <LazyLoad
                                                    src={
                                                        item?.user?.data?.avatar_url ? (
                                                            item?.user?.data?.avatar_url
                                                        ) : (
                                                            "/student.png"
                                                        )
                                                    }
                                                />
                                                <p>
                                                    <strong>{changeNameToLowerCase(item?.user?.data?.name)}</strong> {item?.data?.text}
                                                </p>
                                            </div>
                                        )
                                    ))
                                ) : comment?.length > comments ? (
                                    comment?.slice(0, comments).map((item, index) => (
                                        commenting ? (
                                            <div key={index} className={styles["other-comments"]} style={{ opacity: "0.5" }}>
                                                <LazyLoad
                                                    src={
                                                        item?.user?.data?.avatar_url ? (
                                                            item?.user?.data?.avatar_url
                                                        ) : (
                                                            "/student.png"
                                                        )
                                                    }
                                                />
                                                <p>
                                                    <strong>{changeNameToLowerCase(item?.user?.data?.name)}</strong> {item?.data?.text}
                                                </p>
                                            </div>
                                        ) : (
                                            <div key={index} className={styles["other-comments"]}>
                                                <LazyLoad
                                                    src={
                                                        item?.user?.data?.avatar_url ? (
                                                            item?.user?.data?.avatar_url
                                                        ) : (
                                                            "/student.png"
                                                        )
                                                    }
                                                />
                                                <p>
                                                    <strong>{changeNameToLowerCase(item?.user?.data?.name)}</strong> {item?.data?.text}
                                                </p>
                                            </div>
                                        )
                                    ))
                                ) : null
                            }

                            {
                                activity.latest_reactions?.comment?.length > comments ? (
                                    <p
                                        style={{
                                            fontSize: '16px',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            color: 'grey',
                                        }}
                                        onClick={() => {
                                            setComments(
                                                (comments) => comments + activity.latest_reactions?.comment?.length,
                                            )
                                        }}
                                    >
                                        Load More Comments
                                    </p>
                                ) : null

                            }
                        </div>
                    </div >
                </Row>

            </Col>

        </div>
    );
};

export default ActivityModalFeed;
