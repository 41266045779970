import Router from "./components/Router";
import "./App.css";
import "./edvolve.css";
import "./components/Home/style.css";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import { Router as WrappedRouter } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "./context/ThemeContext";

const history = createBrowserHistory();
ReactGA.initialize("UA-143827867-1");
ReactGA.pageview(window.location.pathname);
console.log(window.location.pathname);
history.listen((location, action) => {
	ReactGA.pageview(location.pathname + location.search);
	console.log(location.pathname);
});

function App() {
	const dark = useContext(ThemeContext).isDark;
	return (
		<div className="App">
			<WrappedRouter history={history}>
				<Router dark={dark} />
			</WrappedRouter>
		</div>
	);
}

export default App;
