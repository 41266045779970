import styles from "./AllMentors.module.css";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import Header from "../Header"
import Footer from "../Footer"
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const AllMentors = (props) => {
    const [mentors, setMentors] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Mentors | HelloWorlds";
        const fetchMentors = async () => {
            try {
                let url = `${process.env.REACT_APP_BASE_URL}/rest/students/mentors`
                let myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + props.access_token);

                let requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                };
                const response = await fetch(url, requestOptions);
                const data = await response.json();
                setMentors(data.data.results);
                setLoading(false);

            } catch (error) {
                console.log("Error fetching mentors", error);
            }
        }
        fetchMentors();
    }, [])

    /*
        Loading Skeleton for the mentors
    */
    const MentorSkeleton = () => {
        return (
            <div className="course-skeleton" style={{ background: "var(--background_primary)" }}>
                <SkeletonTheme baseColor="gray" highlightColor="white">
                    <div>
                        <Skeleton height={200} />
                    </div>
                    <div className="course-skeleton-content">
                        <h1>
                            <Skeleton width="95%" />
                        </h1>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                            }}
                        >
                            <Skeleton inline circle height={40} width={40} />
                            <Skeleton inline height={24} width={150} />
                        </div>
                        <p>
                            <Skeleton
                                inline
                                count={2}
                                height={20}
                                width={100}
                                style={{ marginRight: "10px" }}
                            />
                        </p>
                    </div>
                </SkeletonTheme>
            </div>
        );
    };

    return (
        <>
            <Header
                update_token={props.update_token}
                avatar_url={props.avatar_url}
                access_token={props.access_token}
            />

            <video className="video-background" autoPlay loop muted>
                <source src="/bg.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <Container className="video-bg-content">
                <h3 className={`$my-3 pb-5 pt-2 fs-1 text-center text-white ${styles.animatedHeading}`}>Say Hello to your Mentors!</h3>
                {
                    loading ? (
                        <SkeletonTheme baseColor="gray" highlightColor="white">
                            <Skeleton
                                containerClassName="no-upcoming-webinar"
                                wrapper={MentorSkeleton}
                                count={12}
                            />
                        </SkeletonTheme>
                    ) : mentors.length === 0 ? (
                        <div style={{ height: "16vh" }}>
                            <h2 className="mb-4">No mentors found</h2>
                        </div>
                    ) : (
                        <Row>
                            {mentors.map((mentor) => {
                                return (
                                    <Col xs={12} sm={6} md={4} key={mentor.id} className="mb-4 px-3">
                                        <div className={styles["image-flip"]}>
                                            <div className={`${styles.mainFlip} ${styles["flip-0"]}`}>
                                                <div className={styles.frontside}>
                                                    <div className={`card ${styles.Card}`}>
                                                        <div className={`card-body text-center`}>
                                                            <p>
                                                                <img
                                                                    className={`img-fluid`}
                                                                    src={!mentor.avatar_url ? "./images/teacher.png" : mentor?.avatar_url}
                                                                    alt={mentor?.first_name}
                                                                />
                                                            </p>
                                                            <h4 className={`card-title`}>
                                                                {`${mentor?.first_name} ${mentor?.last_name}`}
                                                            </h4>
                                                            <p className={`card-text text-secondary`}>
                                                                {mentor?.one_liner}
                                                            </p>
                                                            <p className={`card-text text-secondary`}>
                                                                {mentor?.city?.name}
                                                            </p>
                                                            <p className={`card-text text-secondary`}>
                                                                {mentor?.subjects[0]?.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.backside}>
                                                    <div className={`card ${styles.Card}`}>
                                                        <div className={`card-body text-center mt-4`}>
                                                            <h4 className={`card-title`}>{mentor.name}</h4>
                                                            <p className={`card-text text-secondary`}>
                                                                {mentor?.about_me.slice(0, 300)}...
                                                            </p>
                                                            <Link
                                                                to={`/mentor/${mentor.slug}`}
                                                                className={`btn fs-6 btn-outline-primary btn-sm fw-bold text-uppercase`}
                                                            >
                                                                View Profile
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    )
                }

            </Container>
            <Footer />
        </>
    )
}

export default AllMentors