import React, { Component } from "react";
import {
	//BrowserRouter as Router,
	// Router,
	Switch,
	Route,
	// Link,
	Redirect,
	withRouter,
} from "react-router-dom";
import ScrollIntoView from "./ScrollIntoView";
// import { createBrowserHistory } from "history";
// import ReactGA from "react-ga";

import Login from "./Auth/Login";
import Logout from "./Auth/Logout";
// import Register from "./Auth/Register";
import Home from "./Home/Home";
// import Webinar from "./Webinar/Webinar";
// import SingleWebinar from "./Webinar/SingleWebinar";
import ShowWebinar from "./Webinar/ShowWebinar";
import WebinarRecording from "./Webinar/WebinarRecording";
import Mentor from "./Mentor/Mentor";
import EditProfile from "./Auth/EditProfile";
import WebinarQna from "./Webinar/WebinarQna";
import SingleExperience from "./Experience/SingleExperience";
import SingleCourse from "./Course/SingleCourse";
import Experience from "./ExperienceHome/Home";
import Course from "./CourseHome/Home";
import Learn from "./LearnHome/Home";
import ShowCourse from "./Dashboard/ShowCourse";
import ShowExperience from "./Dashboard/ShowExperience";
import Dashboard from "./Dashboard/Dashboard";
import ExpCourseHome from "./CourseExpRec/ExpCourseHome";
import ShowExpRecording from "./CourseExpRec/ShowExpRecording";
import ShowCourseRecording from "./CourseExpRec/ShowCourseRecording";
import Chat from "./StreamChat/Chat";
import LoginGoogle from "./Auth/LoginGoogle";
import LoginFacebook from "./Auth/LoginFacebook";
import ErrorPage from "./ErrorPage";
import SingleWebinarPopup from "./Webinar/SingleWebinarPopup";
import Worlds from "./Worlds";
import SingleClub from "./Worlds/SingleClub";
import Activity from "./Worlds/Activity";
import UserFeed from "./Worlds/UserFeed/UserFeed";
import AllMentors from "./Mentor/AllMentors";
import NewMentor from "./Mentor/NewMentor";
import Faq from "./faq/Faq";
import ShowProfile from "./Auth/ShowProfile";

//var Moengage = require('./utils.js').Moengage;
// var extractUrlParamSignup = require("./utils.js").extractUrlParamSignup;

// const history = createBrowserHistory();
// ReactGA.initialize("UA-143827867-1");
// ReactGA.pageview(window.location.pathname);
// console.log(window.location.pathname);
// history.listen((location, action) => {
// 	ReactGA.pageview(location.pathname + location.search);
// 	console.log(location.pathname);
// });

class Routes extends Component {
	constructor(props) {
		super(props);
		let first_signup = 0;
		//var first_signup_url = extractUrlParamSignup();
		//if(first_signup_url){
		//first_signup = first_signup_url;
		//}
		this.state = {
			access_token: localStorage.getItem("student_token"),
			student_uuid: localStorage.getItem("student_uuid"),
			redirect_to_profile: false,
			redirect_to_login: false,
			first_signup: first_signup,
			student: {
				id: null,
				first_name: null,
				last_name: null,
				dob: null,
				mobile_number: null,
				email: null,
				avatar_url: "/student.png",
				school_id: null,
				grade: null,
				section: null,
				created_at: null,
			},
		};
		this.updateToken = this.updateToken.bind(this);
		this.updateTokenWithRedirect = this.updateTokenWithRedirect.bind(this);
		this.getStudentDetail = this.getStudentDetail.bind(this);
		this.disableRedirectToProfile = this.disableRedirectToProfile.bind(this);
		this.updateImageState = this.updateImageState.bind(this);
	}

	updateTokenWithRedirect() {
		this.setState({
			access_token: localStorage.getItem("student_token"),
			student_uuid: localStorage.getItem("student_uuid"),
		});
		if (this.state.access_token === null || this.state.student_uuid === null) {
			this.setState({
				redirect_to_login: true,
			});
		} else {
			this.getStudentDetail();
		}
	}

	updateToken() {
		this.setState({
			access_token: localStorage.getItem("student_token"),
			student_uuid: localStorage.getItem("student_uuid"),
		});
		if (this.state.access_token === null || this.state.student_uuid === null) {
			this.setState({
				redirect_to_login: true,
			});
		} else {
			this.getStudentDetail();
		}
	}

	updateImageState(avatar_url) {
		let student_state = this.state.student;
		student_state.avatar_url = avatar_url;
		this.setState({
			student: student_state,
		});
	}

	disableRedirectToProfile() {
		this.setState({
			redirect_to_profile: false,
		});
	}

	getStudentDetail() {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.state.access_token);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/${this.state.student_uuid}`;

		fetch(url, requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					//console.log(result["data"]["results"]);
					let data = result["data"] || {};
					if (data.first_name === null || data.dob === null || data.grade === null) {
						//this.setState({
						//	redirect_to_profile: true
						//});
						this.setState({ redirect_to_profile: true }, () => {
							//console.log("State Updated" + this.state.redirect_to_profile);
						});
					} else {
						this.setState({
							student: {
								id: data.id,
								first_name: data.first_name,
								last_name: data.last_name,
								dob: data.dob,
								mobile_number: data.mobile_number,
								email: data.email,
								avatar_url: data.avatar_url,
								school_id: data.classroom
									? data.classroom.institution.id
									: data.classroom,
								grade: data.classroom ? data.classroom.grade : data.classroom,
								section: data.classroom
									? data.classroom.section
									: data.classroom,
								created_at: data.created_ts,
							},
						});
						localStorage.setItem("student", JSON.stringify(this.state.student))
					}
				},
				(error) => { }
			);
	}

	componentDidMount() {
		if (this.state.student_uuid !== null && this.state.access_token !== null) {
			this.getStudentDetail();
		}
	}
	/*
	shouldComponentUpdate(nextProps, nextState) {
	  if(this.state.student.first_name === nextState.student.first_name && this.state.redirect_to_profile === nextState.redirect_to_profile && this.state.redirect_to_login === nextState.redirect_to_login){
			return false;
	  }
	  return true;
	}
*/
	render() {
		//<Route exact path="/chats/:mentor_id" render={(props) => <ChatBox {...props} access_token={this.state.access_token} avatar_url={this.state.student.avatar_url} student={this.state.student} />} />
		if (this.state.student.first_name !== null) {
			window.Moengage.add_first_name(this.state.student.first_name);
			window.Moengage.add_last_name(this.state.student.last_name);
			window.Moengage.add_email(this.state.student.email);
			window.Moengage.add_mobile("+91" + this.state.student.mobile_number);
			window.Moengage.add_user_name(
				this.state.student.first_name + " " + this.state.student.last_name
			);
		}

		//if(this.state.first_signup==1){
		/*	//alert("passed again");
					Moengage.track_event("SignUp", {
					  "first_name":this.state.student.first_name,
					  "last_name":this.state.student.last_name,
					  "grade": this.state.student.grade,
					  "age": this.state.student.dob,
					  "email": this.state.student.email,
					  "mobile_number": this.state.student.mobile_number
					}); */
		//}

		return (
			<>
				<ShowProfile
					access_token={this.state.access_token}
					student_uuid={this.state.student_uuid}
					show={this.state.redirect_to_profile}
				/>
				{this.state.redirect_to_login ? (
					<Switch>
						<Route
							exact
							path="/login"
							render={(props) => (
								<Login update_token={this.updateTokenWithRedirect} />
							)}
						/>
						<Route
							exact
							path="/mentor/:slug"
							render={(props) => (
								<NewMentor
									{...props}
									access_token={this.state.access_token}
									avatar_url={this.state.student.avatar_url}
								/>
							)}
						/>
						<Route render={() => <Redirect to="/login" />} />
					</Switch>
				) : this.state.redirect_to_profile ? (
					<Switch>
						{/* <Route
							exact
							path="/edit-profile"
							render={(props) => (
								<EditProfile
									{...props}
									update_image_state={this.updateImageState}
									disable_redirect_to_profile={this.disableRedirectToProfile}
									show_msg={true}
									access_token={this.state.access_token}
									student_uuid={this.state.student_uuid}
									avatar_url={this.state.student.avatar_url}
									dark={this.props.dark}
								/>
							)}
						/>
						<Route
							exact
							path="/mentor/:slug"
							render={(props) => (
								<NewMentor
									{...props}
									access_token={this.state.access_token}
									avatar_url={this.state.student.avatar_url}
								/>
							)}
						/>
						<Route
							exact
							path="/logout"
							render={(props) => (
								<Logout update_token={this.updateTokenWithRedirect} />
							)}
						/>
						<Route render={() => <Redirect to="/edit-profile" />} /> */}
					</Switch>
				) : (
					<ScrollIntoView>
						<Switch
							location={
								(this.props.location.state &&
									this.props.location.state.background) ||
								this.props.location
							}
						>
							<Route exact path="/auth/google" component={LoginGoogle} />
							<Route exact path="/auth/facebook" component={LoginFacebook} />
							<Route
								exact
								path="/login"
								render={(props) => (
									<Login update_token={this.updateTokenWithRedirect} />
								)}
							/>
							<Route
								exact
								path="/logout"
								render={(props) => (
									<Logout update_token={this.updateTokenWithRedirect} />
								)}
							/>
							<Route
								exact
								path="/mentors"
								render={(props) => (
									<AllMentors
										{...props}
										update_token={this.updateToken}
										current_route="mentors"
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
										student_uuid={this.state.student_uuid}
										student_id={this.state.student.id}
										student={this.state.student}
									/>
								)}
							/>
							<Route
								exact
								path="/dashboard"
								render={(props) => (
									<Dashboard
										{...props}
										update_token={this.updateToken}
										current_route="dashboard"
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
										student_uuid={this.state.student_uuid}
										student_id={this.state.student.id}
										student={this.state.student}
										dark={this.props.dark}
									/>
								)}
							/>
							<Route exact path="/chats/">
								{this.state.access_token ? (
									(props) => (
										<Chat
											{...props}
											update_token={this.updateToken}
											access_token={this.state.access_token}
											student_uuid={this.state.student_uuid}
											avatar_url={this.state.student.avatar_url}
											student={this.state.student}
										/>
									)
								) : (
									<>
										<Login update_token={this.updateToken} location="/chats" />
									</>
								)}
							</Route>
							{/* <Route
								exact
								path="/chats/:mentor_id"
								render={
									this.state.access_token ? (
										(props) => (
											<Chat
												{...props}
												update_token={this.updateToken}
												access_token={this.state.access_token}
												student_uuid={this.state.student_uuid}
												avatar_url={this.state.student.avatar_url}
												student={this.state.student}
											/>
										)
									) : (
										<Login update_token={this.updateToken} location="/chats" />
									)
								}
							/> */}
							<Route
								exact
								path="/past-recordings/worlds"
								render={(props) => (
									<ExpCourseHome
										update_token={this.updateToken}
										{...props}
										current_route="recording-world"
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
										student_uuid={this.state.student_uuid}
										student_id={this.state.student.id}
										student={this.state.student}
										dark={this.props.dark}
									/>
								)}
							/>
							<Route
								exact
								path="/past-recordings/experience"
								render={(props) => (
									<ExpCourseHome
										update_token={this.updateToken}
										{...props}
										current_route="recording-exp"
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
										student_uuid={this.state.student_uuid}
										student_id={this.state.student.id}
										student={this.state.student}
									/>
								)}
							/>
							<Route
								exact
								path="/past-recordings/course"
								render={(props) => (
									<ExpCourseHome
										update_token={this.updateToken}
										{...props}
										current_route="recording-course"
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
										student_uuid={this.state.student_uuid}
										student_id={this.state.student.id}
										student={this.state.student}
									/>
								)}
							/>

							<Route
								exact
								path="/experience/schedule/:schedule_id/recording"
								render={(props) => (
									<ShowExpRecording
										update_token={this.updateToken}
										{...props}
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/>
							<Route
								exact
								path="/course/batch/class/:class_id/recording"
								render={(props) => (
									<ShowCourseRecording
										update_token={this.updateToken}
										{...props}
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/>
							<Route
								exact
								path="/edit-profile"
								render={(props) => (
									<EditProfile
										{...props}
										update_token={this.updateToken}
										update_image_state={this.updateImageState}
										disable_redirect_to_profile={this.disableRedirectToProfile}
										access_token={this.state.access_token}
										student_uuid={this.state.student_uuid}
										avatar_url={this.state.student.avatar_url}
										dark={this.props.dark}
									/>
								)}
							/>
							<Route
								exact
								path="/room/:webinar_id/attend"
								render={(props) => (
									<ShowWebinar
										{...props}
										update_token={this.updateToken}
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/>
							<Route
								exact
								path="/room/:webinar_id/recording"
								render={(props) => (
									<WebinarRecording
										{...props}
										update_token={this.updateToken}
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/>
							<Route
								exact
								path="/room/:webinar_id/qna"
								render={(props) => (
									<WebinarQna
										{...props}
										update_token={this.updateToken}
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/>
							<Route
								exact
								path="/room/:webinar_id"
								render={(props) => (
									<SingleWebinarPopup
										{...props}
										update_token={this.updateToken}
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
										student={this.state.student}
									/>
								)}
							/>
							<Route
								exact
								path="/experience/:time_slot_id/attend"
								render={(props) => (
									<ShowExperience
										{...props}
										update_token={this.updateToken}
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/>
							<Route
								exact
								path="/course/:class_id/attend"
								render={(props) => (
									<ShowCourse
										{...props}
										update_token={this.updateToken}
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/>
							<Route
								exact
								path="/experience/:exp_id"
								render={(props) => (
									<SingleExperience
										{...props}
										update_token={this.updateToken}
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
										student={this.state.student}
									/>
								)}
							/>
							<Route
								exact
								path="/course/:course_id"
								render={(props) => (
									<SingleCourse
										{...props}
										update_token={this.updateToken}
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
										student={this.state.student}
									/>
								)}
							/>
							<Route
								exact
								path="/mentor/:slug"
								render={(props) => (
									<NewMentor
										{...props}
										update_token={this.updateToken}
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
										dark={this.props.dark}
									/>
								)}
							/>
							<Route
								exact
								path="/rooms/"
								render={(props) => (
									<Home
										{...props}
										update_token={this.updateToken}
										current_route="webinars"
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/>
							<Route
								exact
								path="/experiences/"
								render={(props) => (
									<Experience
										{...props}
										update_token={this.updateToken}
										current_route="exp"
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/>
							<Route
								exact
								path="/courses/"
								render={(props) => (
									<Course
										{...props}
										update_token={this.updateToken}
										current_route="courses"
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/>
							<Route
								exact
								path="/learn/"
								render={(props) => (
									<Learn
										{...props}
										update_token={this.updateToken}
										current_route="learn"
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/>
							<Route
								exact
								path="/faqs/"
								render={(props) => (
									<Faq
										{...props}
										update_token={this.updateToken}
										current_route="faqs"
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/>
							<Route
								exact
								path="/worlds/"
								render={(props) => (
									<Worlds
										{...props}
										update_token={this.updateToken}
										current_route="worlds"
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/>
							<Route
								exact
								path={[
									"/worlds/:id/activity",
									"/worlds/:id/activity/",
									"/worlds/:id/activity/calendar",
									"/worlds/:id/activity/library",
									"/worlds/:id/activity/rules",
								]}
							>
								{this.state.access_token ? (
									(props) => (
										<Activity
											{...props}
											update_token={this.updateToken}
											// current_route="worlds"
											access_token={this.state.access_token}
											avatar_url={this.state.student.avatar_url}
											student={this.state.student}
											dark={this.props.dark}
										/>
									)
								) : (
									<Login update_token={this.updateToken} location="/world" />
								)}
							</Route>
							<Route
								exact
								path="/worlds/:id"
								render={(props) => (
									<SingleClub
										{...props}
										update_token={this.updateToken}
										current_route="worlds"
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
										student={this.state.student}
									/>
								)}
							/>
							{/* <Route
								exact
								path="/"
								render={(props) => (
									// <Home
									//   {...props}
									//   update_token={this.updateToken}
									//   current_route="webinars"
									//   access_token={this.state.access_token}
									//   avatar_url={this.state.student.avatar_url}
									// />
									<Learn
										{...props}
										update_token={this.updateToken}
										current_route="learn"
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/> */}

							<Route exact path="/timeline/">
								{this.state.access_token ? (
									(props) => (
										<UserFeed
											{...props}
											update_token={this.updateToken}
											access_token={this.state.access_token}
											student_uuid={this.state.student_uuid}
											avatar_url={this.state.student.avatar_url}
											student={this.state.student}
										/>
									)
								) : (
									<>
										<Login update_token={this.updateToken} location="/timeline" />
									</>
								)}
								{/* {
									(props) => (
										<UserFeed
											{...props}
											update_token={this.updateToken}
											access_token={this.state.access_token}
											student_uuid={this.state.student_uuid}
											avatar_url={this.state.student.avatar_url}
											student={this.state.student}
										/>
									)
								} */}
							</Route>

							{/* <Route exact path="/timeline/">
								{
									(props) => (
										<UserFeed
											{...props}
											update_token={this.updateToken}
											access_token={this.state.access_token}
											student_uuid={this.state.student_uuid}
											avatar_url={this.state.student.avatar_url}
											student={this.state.student}
										/>
									)
								}
							</Route> */}

							<Route path="/">
								{this.state.access_token ? (
									(props) => (
										<UserFeed
											{...props}
											update_token={this.updateToken}
											access_token={this.state.access_token}
											student_uuid={this.state.student_uuid}
											avatar_url={this.state.student.avatar_url}
											student={this.state.student}
										/>
									)
								) : (
									<>
										{/* <Learn
											// {...props}
											update_token={this.updateToken}
											current_route="learn"
											access_token={this.state.access_token}
											avatar_url={this.state.student.avatar_url}
										/> */}
										<Worlds
											// {...props}
											update_token={this.updateToken}
											current_route="worlds"
											access_token={this.state.access_token}
											avatar_url={this.state.student.avatar_url}
										/>
									</>
								)}
							</Route>

							<Route component={ErrorPage} />
						</Switch>
						{this.props.location.state &&
							this.props.location.state.background && (
								<Route
									exact
									path="/room/:webinar_id"
									render={(props) => (
										<SingleWebinarPopup
											{...props}
											update_token={this.updateToken}
											access_token={this.state.access_token}
											avatar_url={this.state.student.avatar_url}
											student={this.state.student}
										/>
									)}
								/>
							)}
						{/* <Switch
							location={
								(this.props.location.state &&
									this.props.location.state.background) ||
								this.props.location
							}
						>
							<Route
								exact
								path="/rooms/"
								render={(props) => (
									<Home
										{...props}
										update_token={this.updateToken}
										current_route="webinars"
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
									/>
								)}
							/>
							<Route
								exact
								path="/room/:webinar_id"
								render={(props) => (
									<SingleWebinarPopup
										{...props}
										update_token={this.updateToken}
										access_token={this.state.access_token}
										avatar_url={this.state.student.avatar_url}
										student={this.state.student}
									/>
								)}
							/>
							{this.props.location.state &&
								this.props.location.state.background && (
									<Route
										exact
										path="/room/:webinar_id"
										render={(props) => (
											<SingleWebinarPopup
												{...props}
												update_token={this.updateToken}
												access_token={this.state.access_token}
												avatar_url={this.state.student.avatar_url}
												student={this.state.student}
											/>
										)}
									/>
								)}
						</Switch> */}
					</ScrollIntoView>
				)}
			</>
		);
	}
}

export default withRouter(Routes);