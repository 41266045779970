import React, { Component } from "react";
import LazyLoad from "../Commons/LazyLoad";
import { Link } from "react-router-dom";
import { Col, Card } from 'react-bootstrap';
import styles from "../Worlds/ClubCard.module.css"

class MentorWorlds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            worlds_snippets: [],
        };
    }

    componentDidMount() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + this.props.access_token);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world?mentor_id=${this.props.mentor_id}`;

        fetch(url, requestOptions)
            .then(function (response) {
                let status = response.status;
                let json_response = response.json();
                if (status !== 200) {
                    if (status === 401) {
                        throw new Error("Your session has expired. Please login again.");
                    } else {
                        throw new Error("Something went wrong. Please contact support.");
                    }
                } else {
                    return json_response;
                }
            })
            .then(
                (result) => {
                    this.setState({
                        worlds_snippets: result["data"]["results"],
                    });
                },
                (error) => { }
            );
    }

    render() {
        return (
            <div>
                {this.state.worlds_snippets.length > 0 ? (
                    <div className="row mentor-webinar">
                        <div className="row margin-minus-five margin-minus-twenty-desktop">
                            {this.state.worlds_snippets.map((snippet, index) => (
                                <Col md={6} lg={4} className={styles.cardOuter + " mentor-worlds"} key={snippet.id}>
                                    <Link
                                        to={`/worlds/${snippet?.id}`}
                                        className={styles.cardLink}
                                    >
                                        <Card
                                            className={`${styles.Card}`}
                                            style={
                                                this.props.dark
                                                    ? {
                                                        boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
                                                    }
                                                    : {
                                                        boxShadow: "#d9d9d9 0px 2px 10px",
                                                    }
                                            }
                                        >
                                            <Card.Header
                                                style={{
                                                    padding: "10px 5px",
                                                    backgroundColor: " rgb(100, 27, 43),",
                                                    borderRadius: "20px",
                                                    border: "0"
                                                }}
                                            >
                                                <div
                                                    className={`position-absolute top-0 ps-3 pe-2 mt-1 fw-bolder ${styles.courseTypeRibbon}`}
                                                >
                                                    Grades {snippet?.grades[0]?.grade}-{snippet?.grades[snippet?.grades.length - 1]?.grade}
                                                </div>

                                                <div
                                                    className={styles.cardHeader}
                                                    style={{
                                                        height: "200px",
                                                        padding: "0",
                                                        margin: "0",
                                                        borderRadius: "20px",
                                                    }}
                                                >
                                                    <LazyLoad
                                                        src={snippet?.image}
                                                        alt="club image"
                                                        classes={styles.banner}
                                                        wrapper="image-lazyload"
                                                    />
                                                </div>
                                            </Card.Header>
                                            <Card.Body style={{ paddingTop: "20px" }}>
                                                <p className="mb-2 mt-1">
                                                    {snippet?.primary_subject?.name + " " + snippet?.secondary_subject?.name}
                                                </p>
                                                <Card.Title style={{ fontWeight: "600" }}>{snippet?.title}</Card.Title>
                                                <Card.Text style={{ color: "var(--fontColor-eo)" }}>
                                                    {
                                                        snippet.tagline && (
                                                            snippet?.tagline?.replace(/(<([^>]+)>)/ig, '').replace(/(&([^>]+);)/ig, '').slice(0, 135) + "..."
                                                        )
                                                    }
                                                </Card.Text>
                                                <p className="m-0 mt-4 p-0 d-flex align-items-center">
                                                    <img
                                                        src={snippet?.uuid?.user?.avatar_url}
                                                        alt="Abhishek"
                                                        style={{
                                                            width: "40px",
                                                            height: "40px",
                                                            borderRadius: "50%",
                                                            display: "flex"
                                                        }}
                                                    />
                                                    <span className="ps-2">
                                                        {snippet?.uuid?.user?.first_name + " " + snippet?.uuid?.user?.last_name}
                                                    </span>
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div
                        className="no-upcoming-webinar mt-4"
                        style={{ flexDirection: "column" }}
                    >
                        <LazyLoad
                            src="/icons/calendar.png"
                            alt="calendar-dashboard"
                            classes="calendar-dashboard"
                            style={{ maxWidth: "200px" }}
                        />
                        <p className="no-bookings-yet m-1">No Worlds created by the mentor.</p>
                    </div>
                )}
            </div>
        );
    }
}

export default MentorWorlds;
