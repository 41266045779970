import React, { Component, StrictMode } from "react";
import Header from "../Header";
import Footer from "../Footer";
import MentorDetails from "./MentorDetails";
import LoginPopup from "../Auth/LoginPopup";
import swal from "sweetalert";
import ReactDOM from "react-dom";
import { capitalizeName } from "../utils";
import LazyLoad from "../Commons/LazyLoad";

class Mentor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			slug: this.props.match.params.slug,
			id: "",
			first_name: "",
			last_name: "",
			avatar_url: null,
			cover_img: null,
			one_liner: "",
			about_me: "",
			city: "",
			modalOpen: false,
		};
	}

	componentDidUpdate() {
		document.title = `${this.state.first_name} ${
			this.state.last_name || ""
		} | HelloWorlds`;
	}

	componentDidMount() {
		let requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/mentors/${this.state.slug}/slug`;

		fetch(url, requestOptions)
			.then((res) => {
				return res.json();
			})
			.then(
				(result) => {
					var final_object = result["data"];
					this.setState({
						id: final_object.uuid.uuid,
						first_name: final_object.first_name,
						last_name: final_object.last_name,
						avatar_url: final_object.avatar_url,
						cover_img: final_object.cover_img,
						one_liner: final_object.one_liner,
						about_me: final_object.about_me,
					});
					if (final_object.city_id !== null) {
						this.setState({
							city: final_object.city.name,
						});
					}
				},
				(error) => {}
			)
			.catch((err) => console.log("error", err));
	}

	render() {
		let cover_img = "/background-mentor.jpg";
		let avatar_url = "/mentor.png";
		if (this.state.cover_img !== null) {
			cover_img = this.state.cover_img;
		}

		if (this.state.avatar_url !== null) {
			avatar_url = this.state.avatar_url;
		}

		const openModal = () => {
			let swal_popup = null;

			if (!!this.props.access_token) {
				this.props.history.push({
					pathname: `/chats/`,
					mentor: {
						id: this.state.slug,
						name: capitalizeName(
							`${this.state.first_name} ${this.state.last_name || ""}`
						),
					},
				});
				return;
			}

			swal_popup = document.createElement("div");
			ReactDOM.render(
				<StrictMode>
					<LoginPopup update_token={this.props.update_token} />
				</StrictMode>,
				swal_popup
			);
			swal({
				content: swal_popup,
				buttons: false,
				className: "swal-login",
				closeOnClickOutside: false,
			});
		};

		return (
			<div>
				<Header
					update_token={this.props.update_token}
					avatar_url={this.props.avatar_url}
					access_token={this.props.access_token}
				/>
				<div className="mentor-container">
					<div>
						<LazyLoad
							src={cover_img}
							alt={this.state.first_name + "'s Cover Image"}
							classes="mentor-background"
						/>
						<div className="d-flex justify-content-between mentor-title-container">
							<div>
								<LazyLoad
									src={avatar_url}
									alt={this.state.first_name + "'s Profile Image"}
									classes="mentor-image"
								/>
							</div>
							<button
								className="mentor-message-btn"
								onClick={openModal}
							>
								Message
							</button>
						</div>
					</div>

					{this.state.id ? (
						<div className="row mentor-full-content mb-5">
							<MentorDetails
								access_token={this.props.access_token}
								mentor_id={this.state.id}
								first_name={this.state.first_name}
								last_name={this.state.last_name}
								one_liner={this.state.one_liner}
								about_me={this.state.about_me}
								city={this.state.city}
								dark={this.props.dark}
							/>
						</div>
					) : (
						""
					)}
				</div>
				<div className="clearfix"></div>
				<Footer />
			</div>
		);
	}
}

export default Mentor;
