import { Col, Tab, Tabs } from "react-bootstrap";
import EventCard from "./EventCard";
import { useState, useEffect } from 'react'

const sortLatestByDate = (a, b) => {
	let dateA = new Date(a?.start_time);
	let dateB = new Date(b?.start_time);
	return dateB - dateA;
};

const sortOldestByDate = (a, b) => {
	let dateA = new Date(a?.start_time);
	let dateB = new Date(b?.start_time);
	return dateA - dateB;
};

const Calendar = (props) => {
	const [pastData, setPastData] = useState([]);
	const [futureData, setFutureData] = useState([]);

	let url = `${process.env.REACT_APP_BASE_URL}/rest/students/webinars?`;
	let myHeaders = new Headers();
	myHeaders.append("Authorization", "Bearer " + props.access_token);

	let requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow",
	};

	const fetchPastEvents = async () => {
		try {
			const response = await fetch(`${url}type=past&world_id=${props.match.params.id}`, requestOptions);
			const data = await response.json();
			setPastData(() => {
				let pastData = data.data.results;
				pastData.sort(sortLatestByDate);
				return pastData;
			});
		} catch (error) {
			console.error("Calendar : ", error);
		}
	}

	const fetchFutureEvents = async () => {
		try {
			const response = await fetch(`${url}&world_id=${props.match.params.id}`, requestOptions);
			const data = await response.json();
			setFutureData(() => {
				let futureData = data.data.results;
				futureData.sort(sortOldestByDate);
				return futureData;
			});
		} catch (error) {
			console.error("Calendar : ", error);
		}
	}

	useEffect(() => {
		fetchPastEvents()
		fetchFutureEvents()
	}, [])

	return (
		<div className="mb-4 gopher-font">
			<h2 className="d-none d-md-block">Rooms</h2>
			<Tabs defaultActiveKey="Upcoming" className="col-12">
				<Tab
					eventKey="Upcoming"
					title="Upcoming"
					tabClassName="experience-tab-title"
				>
					{
						futureData.length === 0 ? <h3 className="m-2 mx-3" style={{ color: "#56ccf2" }}>No Upcoming Rooms</h3> : futureData.map((event, index) => (
							<Col md={9} key={index}>
								<EventCard
									data={event}
									history={props.history}
									dark={props.dark}
									sneakPeek={props.sneakPeek}
								/>
							</Col>
						))
					}
				</Tab>
				<Tab eventKey="Past" title="Past" tabClassName="experience-tab-title">
					{
						pastData.length === 0 ? <h3 className="m-2 mx-3" style={{ color: "#56ccf2" }}>No Past Rooms</h3> : pastData.map((event, index) => (
							<Col md={9} key={index}>
								<EventCard
									data={event}
									history={props.history}
									dark={props.dark}
									sneakPeek={props.sneakPeek}
									access_token={props.access_token}
									past={true}
								/>
							</Col>
						))
					}
				</Tab>
			</Tabs>
		</div>
	);
};

export default Calendar;
