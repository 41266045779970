import React, { Component } from "react";
import MentorIntro from "./MentorIntro";
import AboutMentor from "./AboutMentor";
// import MentorsWebinar from "./MentorsWebinar";
// import MentorsExperience from "./MentorsExperience";
import MentorsCourse from "./MentorsCourse";
import { Tabs, Tab } from "react-bootstrap";
import MentorWorlds from "./MentorWorlds";

class MentorDetails extends Component {
    render() {
        return (
            <div className="mentorTabContainer">
                <MentorIntro
                    first_name={this.props.first_name}
                    one_liner={this.props.one_liner}
                    city={this.props.city}
                />
                <Tabs defaultActiveKey={0}>
                    <Tab
                        eventKey={0}
                        title="Introduction"
                        tabClassName="fw-bolder"
                    >
                        <AboutMentor about_me={this.props.about_me} />
                    </Tab>
                    
                    <Tab eventKey={1} title="Worlds" tabClassName="fw-bolder">
                        <MentorWorlds
                            first_name={this.props.first_name}
                            access_token={this.props.access_token}
                            mentor_id={this.props.mentor_id}
                            dark={this.props.dark}
                        />
                    </Tab>

                    {/* <Tab eventKey={2} title="Classes" tabClassName="fw-bolder">
                        <MentorsCourse
                            first_name={this.props.first_name}
                            access_token={this.props.access_token}
                            mentor_id={this.props.mentor_id}
                        />
                    </Tab> */}
                </Tabs>
            </div>
        );
    }
}

export default MentorDetails;
