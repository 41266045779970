import React, { useState, useEffect, useRef, useContext } from "react";
import { Overlay, Modal, Button } from "react-bootstrap";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import ActivityModalFeed from "./ActivityModalFeed";
import { ThemeContext } from "../context/ThemeContext";
import ReactDOM from "react-dom";
import swal from "sweetalert";

const NotificationPopup = (props) => {
    const dark = useContext(ThemeContext).isDark;
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const history = useHistory();
    const [data, setData] = useState([]);
    const [isSeen, setIsSeen] = useState(false);
    const [loading, setLoading] = useState(false);
    const access_token = props.access_token

    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState([]);
    const student = JSON.parse(localStorage.getItem("student"))

    const getNotifications = async () => {
        try {
            let url = `${process.env.REACT_APP_BASE_URL}/rest/students/`;
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + props.access_token);

            let requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
            const response = await fetch(`${url}world/user-notification-feed`, requestOptions);
            const data = await response.json();
            setIsSeen(data?.data?.results?.slice(0, 8)?.some((item) => item?.is_seen === false))
            setData(data?.data?.results?.slice(0, 8));
        } catch (error) {
            console.error(error);
        }
    }

    const MarkAllAsReadPopup = () => {
        const closePopup = () => swal.close();

        var mark_all_as_read_popup = document.createElement("div");
        ReactDOM.render(
            <React.StrictMode>
                <div>
                    <div
                        style={{
                            backgroundColor: "var(--background_primary)",
                            color: "var(--fontColor-primary)",
                        }}
                        className="row login-page-popup"
                    >
                        <div className="login-box-popup col-sm-12 col-md-12 col-lg-12 col-xs-12">
                            <i
                                className="fa fa-times login-popup-cross-icon"
                                onClick={closePopup}
                                aria-hidden="true"
                            >

                            </i>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 social-login-pop">
                            <h5 className="text-center">
                                Done
                            </h5>
                            <p className="text-center">
                                All notifications marked as read
                            </p>
                        </div>
                    </div>
                </div>
            </React.StrictMode >,
            mark_all_as_read_popup
        );
        swal({
            content: mark_all_as_read_popup,
            buttons: false,
            className: "swal-payment-conf",
            closeOnClickOutside: true,
        });
    };

    useEffect(() => {
        getNotifications()
    }, [])

    const NotificationsItems = (props) => {
        const item = props.notification
        const showModal = () => {
            setModalData(item.activities)
            setModalShow(true)
        }

        const markNotificationSeen = async () => {
            if (item.is_seen === true) {
                showModal();
                return
            }
            try {
                showModal();
                let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/mark-user-notification-read`;
                let myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + access_token);
                myHeaders.append("Content-Type", "application/json");

                let requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify({
                        all: false,
                        id: [item.id]
                    }),
                    redirect: "follow"
                };
                const response = await fetch(url, requestOptions);
                const data = await response.json();
                if (data.success) {
                    getNotifications()
                }
            } catch (error) {
                console.error(error);
            }
        }

        return (
            <div>
                {
                    item.is_seen ? (
                        item.activities.slice(0, 1).map((notification, index) => {
                            return (
                                <p
                                    className="refund-popup-head mb-0 pb-2" key={index}
                                    style={{
                                        color: "var(--fontColor-primary)",
                                        padding: "1rem",
                                        cursor: "pointer",
                                        borderBottom: "1px solid #80808040",
                                        fontSize: "14px"
                                    }}
                                    onClick={
                                        () => {
                                            notification.event ? (
                                                setTimeout(() => {
                                                    markNotificationSeen()
                                                    history.push(`/worlds/${notification.world.id}/activity/calendar`)
                                                }, 1)
                                            ) : (
                                                markNotificationSeen()
                                            )
                                        }
                                    }
                                >
                                    <span>
                                        {notification.actor_data?.name}
                                    </span> {""}

                                    <span
                                        className="fw-light"
                                    >
                                        {
                                            notification.event ? (
                                                `has scheduled a new room on ${notification.event.title} in`
                                            ) : (
                                                "posted in"
                                            )
                                        }
                                    </span> {""}

                                    <span>
                                        {
                                            notification.event ? (
                                                notification.world?.name + "."
                                            ) : (
                                                notification.world?.name
                                            )
                                        }
                                    </span> {""}

                                    <span
                                        className="fw-light"
                                    >
                                        {
                                            notification.event ? (
                                                "Check it out!"
                                            ) : (
                                                "on " + moment.utc(notification.time).local().format('Do MMMM YYYY, h:mm a') + "."
                                            )
                                        }

                                    </span> {""}
                                </p>
                            )
                        })

                    ) : (
                        item.activities.slice(0, 1).map((notification, index) => {
                            return (
                                <p
                                    className="refund-popup-head mb-0 pb-2" key={index}
                                    style={{
                                        background: "var(--background_secondary)",
                                        color: "var(--fontColor-primary)",
                                        padding: "1rem",
                                        cursor: "pointer",
                                        borderBottom: "1px solid #80808040",
                                        fontSize: "14px"
                                    }}
                                    onClick={
                                        () => {
                                            notification.event ? (
                                                setTimeout(() => {
                                                    markNotificationSeen()
                                                    history.push(`/worlds/${notification.world.id}/activity/calendar`)
                                                }, 1)
                                            ) : (
                                                markNotificationSeen()
                                            )
                                        }
                                    }
                                >
                                    <span>
                                        {notification.actor_data?.name}
                                    </span> {""}

                                    <span
                                        className="fw-light"
                                    >
                                        {
                                            notification.event ? (
                                                `has scheduled a new room on ${notification.event.title} in`
                                            ) : (
                                                "posted in"
                                            )
                                        }
                                    </span> {""}

                                    <span>
                                        {
                                            notification.event ? (
                                                notification.world?.name + "."
                                            ) : (
                                                notification.world?.name
                                            )
                                        }
                                    </span> {""}

                                    <span
                                        className="fw-light"
                                    >
                                        {
                                            notification.event ? (
                                                "Check it out!"
                                            ) : (
                                                "on " + moment.utc(notification.time).local().format('Do MMMM YYYY, h:mm a') + "."
                                            )
                                        }
                                    </span> {""}
                                </p>
                            )
                        })

                    )
                }
            </div>
        )
    }

    const PopupContent = (props) => {
        const markNotificationsAsSeen = async () => {
            if (loading || !isSeen) return
            try {
                setLoading(true)
                let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world/mark-user-notification-read`;
                let myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + access_token);
                myHeaders.append("Content-Type", "application/json");

                let requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify({
                        all: true,
                        id: []
                    }),
                    redirect: "follow"
                };
                const response = await fetch(url, requestOptions);
                const data = await response.json();
                if (data.success) {
                    getNotifications()
                    setLoading(false)
                    MarkAllAsReadPopup()
                }
                else {
                    console.log("error")
                    setLoading(false)
                }
            } catch (error) {
                console.error(error);
                setLoading(false)
            }
        }

        const notifications = props.notifications
        return (
            <>
                <div className="w-100 d-flex justify-content-between align-items-center"
                    style={{ padding: "1rem" }}
                >
                    <h5 className="refund-popup-hero-head m-0 fw-bold">
                        Notifications
                    </h5>
                    <div
                        className="d-flex align-items-center"
                    >
                        <p
                            className="m-0 fw-bold btn-boo"
                            style={{
                                color: "var(--fontColor-primary)",
                                fontSize: "15px",
                                cursor: "pointer",
                            }}
                            onClick={markNotificationsAsSeen}
                        >
                            Mark all as read
                        </p>
                        <i
                            className="fa fa-times-circle ms-3 fs-4 dark-font-pri"
                            aria-hidden="true"
                            onClick={() => setShow(false)}
                            style={{ cursor: "pointer" }}
                        ></i>
                    </div>
                </div>
                {
                    notifications.length === 0 ? (
                        <div className="mt-3">
                            <p className="refund-popup-head fw-bold px-3">
                                No Notifications
                            </p>
                        </div>
                    ) : (
                        notifications.map((notification) => {
                            return (
                                <NotificationsItems key={notification.id} notification={notification} />
                            )
                        })
                    )
                }
            </>
        );
    };

    const ActivityModal = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="text-warning border-0" closeButton
                    style={
                        dark ? {
                            filter: "invert(1)",
                        } : {}
                    }
                >
                    <Modal.Title id="contained-modal-title-vcenter"
                        style={
                            dark ? {
                                filter: "invert(1)",
                                color: "var(--border)"
                            } : {
                                color: "var(--border)",
                            }
                        }
                    >
                        Notifications from {modalData.length > 0 && modalData[0].world?.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column ">
                    {
                        modalData.length > 0 && modalData.map((activity) => {
                            return (
                                <div className="activity-modal-feed" key={activity.id}>
                                    <ActivityModalFeed
                                        activity={activity}
                                        student={student}
                                        access_token={access_token}
                                        dark={dark}
                                    />
                                </div>

                            )
                        })
                    }

                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <>
            <div
                className={`${!isSeen ? "notification-image-hidden" : "notification-image"}`}
            >
                <img
                    src="/icons/bellicon.png"
                    alt="refund icon img"
                    className="avatar-icon position-relative "
                    ref={target}
                    onClick={() => setShow(!show)}
                />
            </div>

            <Overlay target={target.current} show={show} placement="bottom-start">
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div
                        {...props}
                        style={{
                            position: "absolute",
                            zIndex: 1000,
                            ...props.style,
                            minHeight: "600px",
                            width: "350px",
                            borderRadius: "30px"
                        }}
                        className="notifications-popup-content"
                    >
                        <PopupContent notifications={data} />
                    </div>
                )}
            </Overlay>
            <ActivityModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default NotificationPopup;