import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import LazyLoad from "../Commons/LazyLoad.jsx";
var upcomingDateTime = require("../utils.js").upcomingDateTime;

class UpcomingCourseCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			snippets: [],
		};
	}

	componentDidMount() {
		if (this.props.student_id !== null) {
			let myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + this.props.access_token);

			let requestOptions = {
				method: "GET",
				headers: myHeaders,
				redirect: "follow",
			};

			let url = `${process.env.REACT_APP_BASE_URL}/rest/students/${this.props.student_id}/courses/bookings`;

			fetch(url, requestOptions)
				.then((res) => res.json())
				.then(
					(result) => {
						this.setState({
							snippets: result.data.results,
						});
					},
					(error) => {}
				);
		}
	}

	render() {
		let scrollBox = null;
		const scrollSpeed = 5;
		const scrollLength = 200;
		const scrollSteps = 2;
		const getCarouselScroll = () => {
			scrollBox = document.getElementById("custom-carousel");
		};

		const scrollRightBtn = () => {
			sideScroll(scrollBox, "right", scrollSpeed, scrollLength, scrollSteps);
		};

		const scrollLeftBtn = () => {
			sideScroll(scrollBox, "left", scrollSpeed, scrollLength, scrollSteps);
		};

		function sideScroll(element, direction, speed, distance, step) {
			let scrollAmount = 0;
			var slideTimer = setInterval(function () {
				if (direction === "left") {
					element.scrollLeft -= step;
				} else {
					element.scrollLeft += step;
				}
				scrollAmount += step;
				if (scrollAmount >= distance) {
					window.clearInterval(slideTimer);
				}
			}, speed);
		}

		return (
			<div className="upcoming-exp-dashboard-div" style={{background:"var(--background_primary)"}}>
				<h2>Your Upcoming Courses</h2>
				<div className="carousel-parent-dashboard position-relative">
					{this.state.snippets.length ? (
						<div
							className="col-12 custom-carousel py-3 "
							id="custom-carousel"
							onLoad={getCarouselScroll}
						>
							<div
								className="arrow-button position-absolute top-50 start-0 translate-middle-y"
								onClick={scrollLeftBtn}
							>
								<i className="fa fa-arrow-left" aria-hidden="true"></i>
							</div>
							{this.state.snippets.map((snippet) => (
								<div className="col-10 col-sm-8 col-md-8 col-lg-7 col-xl-5">
									<WithStyles snippet={snippet} />
								</div>
							))}
							<div
								className="arrow-button position-absolute top-50 end-0 translate-middle-y"
								onClick={scrollRightBtn}
							>
								<i className="fa fa-arrow-right" aria-hidden="true"></i>
							</div>
						</div>
					) : (
						<div className="no-upcoming-dashboard">
							<LazyLoad
								src="/icons/calendar.png"
								alt="calendar-dashboard"
								classes="calendar-dashboard"
							/>
							<p className="no-bookings-yet">
								You have not made any bookings yet.
							</p>
							<button className="dashboard-browse-btn">
								<Link to="/learn">Browse</Link>
							</button>
						</div>
					)}
				</div>
			</div>
		);
	}
}

class WithStyles extends Component {
	render() {
		//let duration = Math.round((this.props.snippet.end_time - this.props.snippet.start_time)/60);
		return (
			<div className="experience-snippet-dashboard-parent">
				<div className="experience-snippet-dashboard">
					<a href={"/course/" + this.props.snippet.batch.courses.id}>
						<LazyLoad
							src={this.props.snippet.batch.courses.featured_image}
							alt="snippet-dashboard-img"
							classes="snippet-dashboard-img"
						/>
					</a>
					<div className="exp-snippet-dashboard-texts">
						<p className="snippet-date-dashboard">
							{upcomingDateTime(this.props.snippet.start_time)}
						</p>
						<h3 className="snippet-dashboard-text">
							<span className="snippet-dashboard-class-name">
								{this.props.snippet.class_name}
							</span>
							: {this.props.snippet.batch.courses.title}
						</h3>
						<p className="snippet-with-dashboard-text">
							By {this.props.snippet.batch.courses.mentor.first_name}{" "}
							{this.props.snippet.batch.courses.mentor.last_name}
						</p>
						<div className="snippet-dashboard-last">
							<a
								href={"/course/" + this.props.snippet.time_slot_id + "/attend"}
								target="_blank"
								rel="noopener noreferrer"
							>
								<button className="snippet-dashboard-attend">
									Join
								</button>
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default UpcomingCourseCard;
