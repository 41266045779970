import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import Header from "../Header";
// import SubHeader from "../SubHeader";
import swal from "sweetalert";
import { Redirect } from "react-router";
// import OtpInput from "react-otp-input";
import AppFloatingInput from "../AppComponents/AppFloatingInput";

// import Footer from "../Footer";
import ReactDOM from "react-dom";
import InterestPopup from "./InterestPopup";

//var Moengage = require('../utils.js').Moengage;

class ProfilePopup extends Component {
	render() {
		if (this.props.access_token === null) {
			return <Redirect to="/login" />;
		}
		return (
			<div>
				<div className="edit-profile-container-popup">
					<div className="edit-input-div">
						<InputBox
							update_image_state={this.props.update_image_state}
							disable_redirect_to_profile={
								this.props.disable_redirect_to_profile
							}
							access_token={this.props.access_token}
							student_uuid={this.props.student_uuid}
						/>
					</div>
				</div>
			</div>
		);
	}
}

class InputBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			first_name: null,
			last_name: null,
			email: null,
			dob: null,
			mobile_number: null,
			avatar_url: "/student.png",
			//school_id: 0,
			//school_option: {"value": 0, "label": "test"},
			city_option: { value: 2, label: "Bengaluru" },
			city_id: 0,
			grade: 0,
			section: "A",
			//schools: [],
			cities: [],
			redirect_to_home: false,
			enable_mob_change: false,
			first_time_signup: 0,
			error: false,
		};
		this.editProfile = this.editProfile.bind(this);
		this.pushMoengageEvent = this.pushMoengageEvent.bind(this);
	}

	componentDidMount() {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let url1 = `${process.env.REACT_APP_BASE_URL}/rest/cities`;
		let url2 = `${process.env.REACT_APP_BASE_URL}/rest/students/${this.props.student_uuid}`;

		Promise.all([fetch(url1, requestOptions), fetch(url2, requestOptions)])
			.then(([res1, res2]) => {
				return Promise.all([res1.json(), res2.json()]);
			})
			.then(
				([result1, result2]) => {
					//console.log(result["data"]["results"]);
					this.setState({
						first_name: result2["data"].first_name,
						last_name: result2["data"].last_name,
						email: result2["data"].email,
						dob: result2["data"].dob,
						mobile_number: result2["data"].mobile_number,
						avatar_url: result2["data"].avatar_url,
						cities: result1["data"].results,
					});

					if (result2["data"].classroom !== null) {
						this.setState({
							//school_id: result2["data"].classroom.institution.id,
							//school_option: {value: result2["data"].classroom.institution.id, label: result2["data"].classroom.institution.name},
							grade: result2["data"].classroom.grade,
							section: result2["data"].classroom.section,
						});
					}

					if (result2["data"].city !== null) {
						this.setState({
							city_option: {
								value: result2["data"].city.id,
								label: result2["data"].city.name,
							},
							city_id: result2["data"].city.id,
						});
					}

					if (result2["data"].first_name === null) {
						this.setState({
							first_time_signup: 1,
						});
					}
				},
				(error) => { }
			);
	}

	changeValue(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	changeCityValue(e) {
		this.setState({
			city_id: e.target.value,
			city_option: { value: e.target.value, label: e.label },
		});
	}

	editProfile() {
		const required_fields = ["first_name", "email", "dob", "grade", "city_id", "mobile_number"];
		if (!required_fields.every((field) => this.state[field] !== null && this.state[field] !== "")) {
			this.setState({ error: true });
			return;
		}
		else {
			this.setState({ error: false });
			var myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + this.props.access_token);
			myHeaders.append("Content-Type", "application/json");

			var raw = JSON.stringify({
				first_name: this.state.first_name,
				last_name: this.state.last_name,
				email: this.state.email,
				avatar_url: this.state.avatar_url,
				dob: this.state.dob,
				city_id: this.state.city_id,
				school_id: this.state.school_id,
				grade: this.state.grade,
				section: this.state.section,
				mobile_number: this.state.mobile_number,
			});

			var requestOptions = {
				method: "PUT",
				headers: myHeaders,
				body: raw,
				redirect: "follow",
			};

			let url = `${process.env.REACT_APP_BASE_URL}/rest/students/${this.props.student_uuid}`;

			fetch(url, requestOptions)
				.then((res) => res.json())
				.then(
					(result) => {
						if (result.success === true) {
							this.pushMoengageEvent();
						}
					},
					(error) => { }
				);
		}
	}

	pushMoengageEvent() {
		if (this.state.first_time_signup === 1) {
			//alert("Pushing to Moengage.");
			window.Moengage.add_first_name(this.state.first_name);
			window.Moengage.add_last_name(this.state.last_name);
			window.Moengage.add_email(this.state.email);
			window.Moengage.add_user_name(
				this.state.first_name + " " + this.state.last_name
			);

			window.Moengage.track_event("SignUp", {
				first_name: this.state.first_name,
				last_name: this.state.last_name,
				grade: this.state.grade,
				age: this.state.dob,
				email: this.state.email,
				mobile_number: this.state.mobile_number,
			});
		}

		swal.close();
		var swal_interest_popup = document.createElement("div");
		ReactDOM.render(
			<React.StrictMode>
				<InterestPopup
					access_token={this.props.access_token}
					student_uuid={this.props.student_uuid}
				/>
			</React.StrictMode>,
			swal_interest_popup
		);

		swal({
			content: swal_interest_popup,
			buttons: false,
			className: "swal-interest",
			closeOnClickOutside: false,
		});
	}

	render() {
		let avatar_url = "/student.png";
		if (this.state.avatar_url !== null) {
			avatar_url = this.state.avatar_url;
		}
		var options = [];
		this.state.cities.forEach((city) => {
			options.push({ value: city.id, label: city.name });
		});
		//this.state.schools.map((school) => (
		//	options.push({ "value": school.id, "label": school.name });
		//));
		let home_url = "/";
		//alert(this.state.redirect_to_home);
		if (
			this.state.redirect_to_home === true &&
			this.state.first_time_signup === 1
		) {
			/*	Moengage.track_event("SignUp", {
					  "first_name":this.state.first_name,
					  "last_name":this.state.last_name,
					  "grade": this.state.grade,
					  "age": this.state.dob,
					  "email": this.state.email,
					  "mobile_number": this.state.mobile_number
					}); */
			home_url = "/?first_time_signup=1";
			//alert("redirecting happening");
		}

		let gradeOption = [
			{ value: 0, label: "Please select your grade" },
			{ value: 1, label: "1" },
			{ value: 2, label: "2" },
			{ value: 3, label: "3" },
			{ value: 4, label: "4" },
			{ value: 5, label: "5" },
			{ value: 6, label: "6" },
			{ value: 7, label: "7" },
			{ value: 8, label: "8" },
			{ value: 9, label: "9" },
			{ value: 10, label: "10" },
			{ value: 11, label: "11" },
			{ value: 12, label: "12" },
		];

		return this.state.redirect_to_home ? (
			<Redirect to={home_url} />
		) : (
			<div className="col-12 py-4 px-5 profile-popup-main">
				<h4 className="profile-popup-header p-0">Tell us about you!</h4>
				<div className="edit-profile-photo hide-it">
					<img src={avatar_url} className="edit-profile-img" alt="" />
				</div>
				<div className="mb-2">Hi! My name is </div>
				<div className="row">
					<div className="col-6">
						<AppFloatingInput
							type="text"
							placeholder="First Name"
							label="First Name"
							name="first_name"
							style={{ background: "var(--background_tertiary)" }}
							defaultValue={this.state.first_name}
							onChange={(event) => {
								this.changeValue(event);
							}}
						/>
					</div>
					<div className="col-6">
						<AppFloatingInput
							type="text"
							name="last_name"
							placeholder="Last Name"
							label="Last Name"
							style={{ background: "var(--background_tertiary)" }}
							defaultValue={this.state.last_name}
							onChange={(event) => {
								this.changeValue(event);
							}}
						/>
					</div>
					{
						this.state.error && (this.state.first_name === null || this.state.first_name === "") && (
							<p className="text-danger pe-3 mt-1">Please enter your first name</p>
						)
					}
				</div>
				<div className="mt-3 mb-2"> I was born on </div>
				<AppFloatingInput
					type="date"
					name="dob"
					style={{
						background: "var(--background_tertiary)",
						paddingTop: ".625rem"
					}}
					defaultValue={this.state.dob}
					onChange={(event) => {
						this.changeValue(event);
					}}
				/>
				{
					this.state.error && (this.state.dob === null || this.state.dob === "") && (
						<p className="text-danger pe-3 mt-1">Please enter your date of birth</p>
					)
				}
				<div className="mt-3 mb-2">and currently study in grade</div>
				<AppFloatingInput
					name="grade"
					selectOptions={gradeOption}
					style={{
						background: "var(--background_tertiary)",
						paddingTop: ".625rem"
					}}
					value={this.state.grade}
					onChange={(event) => {
						this.changeValue(event);
					}}
				/>
				{
					this.state.error && (this.state.grade === null || this.state.grade === "" || this.state.grade === 0) && (
						<p className="text-danger pe-3 mt-1">Please enter your grade</p>
					)
				}
				<div className="mt-3 mb-2">in city</div>
				<AppFloatingInput
					name="city_id"
					selectOptions={options}
					value={this.state.city_option.value}
					style={{
						background: "var(--background_tertiary)",
						paddingTop: ".625rem"
					}}
					onChange={(event) => {
						this.changeCityValue(event);
					}}
				/>
				{
					this.state.error && (this.state.city_id === null || this.state.city_id === "") && (
						<p className="text-danger pe-3 mt-1">Please enter your city</p>
					)
				}
				<div className="mt-3 mb-2"> My mobile number is </div>
				<AppFloatingInput
					type="number"
					defaultValue={this.state.mobile_number}
					name="mobile_number"
					onChange={(event) => {
						this.changeValue(event);
					}}
					style={{
						background: "var(--background_tertiary)",
						paddingTop: ".625rem",
						opacity: this.state.mobile_number !== null ? 0.5 : 1,
					}}
					disabled={this.state.mobile_number !== null}
				/>
				{
					this.state.error && (this.state.mobile_number === null || this.state.mobile_number === "") && (
						<p className="text-danger pe-3 mt-1">Please enter your mobile number</p>
					)
				}

				<div className="mt-3 mb-2">and email address is</div>
				<AppFloatingInput
					type="email"
					name="email"
					style={{
						background: "var(--background_tertiary)",
						paddingTop: ".625rem"
					}}
					defaultValue={this.state.email}
					onChange={(event) => {
						this.changeValue(event);
					}}
				/>
				{
					this.state.error && (this.state.email === null || this.state.email === "") && (
						<p className="text-danger pe-3 mt-1">Please enter your email</p>
					)
				}

				<div className="mob-change-div hide-it">
					<span>{this.state.mobile_number}</span>
					<span className="change-mob" onClick={this.showOTPBox}>
						Change
					</span>
				</div>
				<input
					type="text"
					name="section"
					className="profile-input-popup hide-it"
					defaultValue={this.state.section}
					onChange={(event) => {
						this.changeValue(event);
					}}
				/>
				<button
					className="edit-profile-btn-popup fw-bold"
					onClick={this.editProfile}
				>
					Save Changes
				</button>
			</div>
		);
	}
}

export default ProfilePopup;
