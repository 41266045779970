import { Offcanvas, Tabs, Tab } from 'react-bootstrap';
import LazyLoad from '../Commons/LazyLoad';
import React, { useState } from 'react';
import moment from 'moment';
import swal from 'sweetalert';
import ReactDOM from 'react-dom';
import { createChannel, addChannelMembers, getChannels } from "../StreamChat/utils"
import { useHistory } from 'react-router-dom'

const handleProfilePic = (img) => {
    if (img === null || img === undefined) {
        return "/student.png"
    } else if (img === "" || img === "NA") {
        return "/student.png"
    } else {
        return img
    }
}

const calculateLastSeen = (lastSeen) => {
    const now = new Date();
    const lastSeenDate = new Date(lastSeen);
    const timeDifference = now - lastSeenDate;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
        return `${seconds} seconds ago`;
    } else if (minutes < 60) {
        return `${minutes} minutes ago`;
    } else if (hours < 24) {
        return `${hours} hours ago`;
    } else if (days < 30) {
        return `${days} days ago`;
    } else if (months < 12) {
        return `${months} months ago`;
    } else {
        return `${years} years ago`;
    }
};

const confPopup = () => {
    var conf_popup = document.createElement("div");
    ReactDOM.render(
        <React.StrictMode>
            <ConfPopuupContent />
        </React.StrictMode>,
        conf_popup
    );
    swal({
        content: conf_popup,
        buttons: false,
        className: "swal-ops",
        closeOnClickOutside: true,
    });
};

const ConfPopuupContent = () => {
    return (
        <div>
            <h6 className="text-center mb-3">
                Oops. We'll be launching member-to-member chat in a few days.
            </h6>

            <button
                type='button'
                onClick={() => swal.close()}
                style={{
                    cursor: "pointer",
                    fontSize: "12px",
                    height: "28px"
                }}
            >
                OK, Will Wait
            </button>
        </div>
    );
};


const UserProfileSidebar = ({ show, handleClose, dark, data, sneakPeek }) => {
    const [key, setKey] = useState('about');
    const [chatLoading, setChatLoading] = useState(false);
    const history = useHistory();

    const handleChat = async () => {
        setChatLoading(true);
        try {
            // checking if channel already exists with the same user
            const existingChannels = await getChannels();
            if (existingChannels?.data?.channels?.length) {
                const existingChannel = existingChannels?.data?.channels?.find((channel) => {
                    return channel?.members[0]?.user?.id === data?.uuid?.uuid
                })
                // If channel exists, redirect to that channel
                if (existingChannel) {
                    history.push("/chats", { channel: existingChannel })
                    return
                }
            }

            // If channel doesn't exist, create a new channel
            const createChannelRes = await createChannel({
                group: 0,
                world_id: data?.world_id,
                name: `${data?.uuid?.student?.first_name} ${data?.uuid?.student?.last_name}`,
            });

            // If channel is created, add the user to the channel
            if (createChannelRes?.data?.channel) {
                const addMemberRes = await addChannelMembers({
                    channel_name: createChannelRes?.data?.channel?.id,
                    members: [data?.uuid?.uuid],
                });
                if (addMemberRes?.data) {
                    history.push("/chats", { channel: addMemberRes?.data })
                    return
                } else {
                    throw new Error("Something went wrong")
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setChatLoading(false);
        }
    }

    return (
        <Offcanvas
            show={show}
            onHide={handleClose}
            placement='end'
            style={{
                background: "var(--background_primary)",
                color: "var(--textColor_primary)",
            }}
        >
            <Offcanvas.Header>
                <div className='d-flex justify-content-between w-100 px-3'>
                    <h4>
                        Profile
                    </h4>
                    <div>
                        <img
                            src={dark ? "/icons/cross.png" : "/icons/cross_black.png"}
                            alt="HelloWorlds"
                            style={{
                                width: "30px",
                                cursor: "pointer",
                            }}
                            onClick={handleClose}
                        />
                    </div>
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body className="hide-scroll-bar" style={window.innerWidth < 768 ? { paddingBottom: "100px" } : {}}>
                <div className="d-flex gap-3 align-items-center">
                    <div>
                        <LazyLoad
                            src={handleProfilePic(data?.uuid?.student?.avatar_url)}
                            alt="mentor"
                            classes="rounded-circle"
                            height="100px"
                            width="100px"
                        />
                    </div>
                    <div className='d-flex flex-column gap-1 pt-2'>
                        <h6 className='mb-0'>
                            {`${data?.uuid?.student?.first_name} ${data?.uuid?.student?.last_name}`}
                        </h6>
                        <p
                            className="font-color-secondary"
                            style={{
                                marginTop: "-4px",
                                fontSize: "14px",
                                marginBottom: "4px",
                                paddingLeft: "1px"
                            }}
                        >
                            {moment(data?.uuid?.student?.dob).diff(moment(), 'years') * -1} years old
                        </p>
                        <button
                            style={{
                                background: "var(--background_primary)",
                                color: "var(--textColor_secondary)",
                                boxShadow: "var(--shadow_primary)",
                                padding: "7px 10px",
                                width: "100px",
                                borderRadius: "20px",
                                fontSize: "12px",
                            }}
                            // onClick={() => confPopup()}
                            onClick={() => handleChat()}
                            disabled={chatLoading || sneakPeek}
                        >
                            Message
                        </button>
                    </div>
                </div>

                <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="px-2"
                >
                    <Tab eventKey="about" title="About">
                        <div className='ps-4 mt-2'>
                            <h5>
                                Interests
                            </h5>
                            <div className="d-flex flex-wrap align-items-center gap-3 mt-3">
                                {
                                    data?.uuid?.student?.interest?.map((interest, i) => (
                                        <span
                                            className="profile-interest"
                                            key={i}
                                        >
                                            {interest?.name}
                                        </span>
                                    ))
                                }
                            </div>
                        </div>

                        <div className='ps-4 mt-4'>
                            <div className='d-flex align-items-center gap-3 mb-3'>
                                <img
                                    src="/icons/location.png"
                                    alt="HelloWorlds"
                                    style={
                                        dark ?
                                            {
                                                width: "20px",
                                                filter: "invert(1)",
                                            }
                                            : {
                                                width: "20px",
                                            }
                                    }
                                />
                                <p>
                                    {data?.uuid?.student?.city?.name}
                                </p>
                            </div>

                            <div className='d-flex align-items-center gap-3 mb-3'>
                                <img
                                    src="/icons/cal.png"
                                    alt="HelloWorlds"
                                    style={
                                        dark ?
                                            {
                                                width: "20px",
                                                filter: "invert(1)",
                                            }
                                            : {
                                                width: "20px",
                                            }
                                    }
                                />
                                <p>
                                    Member since {moment(data?.active_since).format("MMM DD, YYYY")}
                                </p>
                            </div>

                            {/* <div className='d-flex align-items-center gap-3'>
                                <img
                                    src="/icons/clk.png"
                                    alt="HelloWorlds"
                                    style={
                                        dark ?
                                            {
                                                width: "20px",
                                                filter: "invert(1)",
                                            }
                                            : {
                                                width: "20px",
                                            }
                                    }
                                />
                                <p>
                                    Last seen {calculateLastSeen(data?.last_seen)}
                                </p>
                            </div> */}
                        </div>
                    </Tab>

                    {/* <Tab eventKey="worlds" title="Worlds">
                        <div className="ps-4 mt-2">
                            <h5>
                                Joined Worlds
                            </h5>
                            <div className="d-flex flex-wrap align-items-center gap-3 mt-3">

                                {
                                    Array(10).fill().map((_, i) => (
                                        <Link to="/worlds" key={i} className="card-link">
                                            <Card
                                                style={
                                                    dark
                                                        ? {
                                                            boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
                                                            background: "var(--background_primary)",
                                                            borderRadius: "21px",
                                                            width: window.innerWidth > 400 ? "150px" : "140px",
                                                        }
                                                        : {
                                                            boxShadow: "#d9d9d9 0px 2px 10px",
                                                            background: "var(--background_primary)",
                                                            borderRadius: "21px",
                                                            width: window.innerWidth > 400 ? "150px" : "140px",
                                                        }
                                                }
                                            >
                                                <Card.Img
                                                    variant="top"
                                                    src="https://sgp1.digitaloceanspaces.com/static-edvolve/media/MjCXRjrf9KghZdodYdb4ieS7ScQ488BrAsZafaMX.jpg"
                                                />
                                                <Card.Body className="p-2">
                                                    <Card.Title style={{ fontSize: "14px" }}>
                                                        Test World with free membership
                                                    </Card.Title>
                                                    <Card.Text style={{ fontSize: "12px" }} className='font-color-secondary'>
                                                        With Abhishek
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Link>
                                    ))
                                }
                            </div>

                        </div>
                    </Tab> */}
                </Tabs>
            </Offcanvas.Body>
        </Offcanvas >
    );
}

export default UserProfileSidebar;