import React, { Component } from "react";
// import { Link } from "react-router-dom";
import ReactDOM from "react-dom";

// import { Redirect } from "react-router";
import swal from "sweetalert";
// import Header from "../Header";
import EnterMobile from "./EnterMobile";
import EnterOTP from "./EnterOTP";
import ProfilePopup from "./ProfilePopup";

// import Footer from "../Footer";
import { ThemeContext } from "../../context/ThemeContext";

//var Moengage = require('../utils.js').Moengage;

class LoginPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPhone: true,
			mob: 0,
			redirect: false, //redirects after login,
			first_time_user: 0,
			googleLoginURL: null,
			popupText:
				"Hangout and learn with your favorite creators & educators. Be a part of amazing clubs and level up on your passion.",
			// facebookLoginURL: null,
		};
		this.mobHandler = this.mobHandler.bind(this);
		this.otpHandler = this.otpHandler.bind(this);
	}

	componentDidMount() {
		this.loginGoogle();
		// this.loginFacebook();

		if (this.props?.from === "soundpod") {
			this.setState({
				popupText:
					"This and tons of other life-changing sessions waiting. One signup away.",
			});
		} else if (this.props?.from === "mindpod") {
			this.setState({
				popupText:
					"This and many other life-changing classes from international educators. One signup away.",
			});
		}
	}

	mobHandler(mob) {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({ phone_number: mob });

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			`${process.env.REACT_APP_BASE_URL}/auth/students/sendotp`,
			requestOptions
		)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					// throw new Error(
					//     "Something went wrong. Please contact support."
					// );
					return Promise.reject();
				}
			})
			.then((result) => {
				//alert(result.success);
				this.setState({
					showPhone: false,
					mob: mob,
					first_time_user: result.data.first_time_user,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	otpHandler(otp) {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({ phone_number: this.state.mob, otp: otp });

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(
			`${process.env.REACT_APP_BASE_URL}/auth/students/verifyotp`,
			requestOptions
		)
			.then(function (response) {
				let status = response.status;
				let json_response = response.json();
				if (status !== 200) {
					swal("OTP doesn't seem to be right. Please recheck.");
					if (status === 400 || status === 404) {
						console.log(json_response);
						throw new Error("Something went wrong. Please contact support.");
					} else {
						throw new Error("Something went wrong. Please contact support.");
					}
				} else {
					return json_response;
				}
			})
			.then(
				(result) => {
					localStorage.setItem("student_uuid", result.data.user_uuid);
					localStorage.setItem("student_token", result.data.token);
					window.Moengage.add_unique_user_id(result.data.user_uuid);
					this.props?.update_token();
					swal.close();
					if (this.state.first_time_user === 1) {
						var swal_profile_popup = document.createElement("div");
						ReactDOM.render(
							<React.StrictMode>
								<ProfilePopup
									access_token={result.data.token}
									student_uuid={result.data.user_uuid}
								/>
							</React.StrictMode>,
							swal_profile_popup
						);
						swal({
							content: swal_profile_popup,
							buttons: false,
							className: "swal-login",
							closeOnClickOutside: false,
						});
					} else {
						window.location.reload();
					}
				},
				(error) => {
					//alert(error);
				}
			);
	}

	closePopup() {
		swal.close();
	}

	loginGoogle() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(
			`${process.env.REACT_APP_BASE_URL}/login/google/redirect`,
			requestOptions
		)
			.then(function (response) {
				if (response.ok) {
					return response.json();
				}
				throw new Error("Something went wrong!");
			})
			.then((data) => this.setState({ googleLoginURL: data.url }))
			.catch((error) => console.error(error));
	}

	// loginFacebook() {
	//   var myHeaders = new Headers();
	//   myHeaders.append("Content-Type", "application/json");

	//   var requestOptions = {
	//     method: "GET",
	//     headers: myHeaders,
	//     redirect: "follow",
	//   };

	//   fetch(
	//     `${process.env.REACT_APP_BASE_URL}/login/facebook/redirect`,
	//     requestOptions
	//   )
	//     .then(function (response) {
	//       if (response.ok) {
	//         return response.json();
	//       }
	//       throw new Error("Something went wrong!");
	//     })
	//     .then((data) => this.setState({ facebookLoginURL: data.url }))
	//     .catch((error) => console.error(error));
	// }

	static contextType = ThemeContext;

	render() {
		return (
			<div>
				<div
					style={{
						backgroundColor: "var(--background_primary)",
						color: "var(--fontColor-primary)",
					}}
					className="row login-page-popup"
				>
					<div className="login-box-popup col-sm-12 col-md-12 col-lg-12 col-xs-12">
						<i
							className="fa fa-times login-popup-cross-icon"
							onClick={() => this.closePopup()}
							aria-hidden="true"
						></i>
						<img
							src="/edvolve_full.png"
							alt="edvolve-full-logo"
							className="edvolve-full-logo"
							style={{ position: "relative", right: "26px" }}
						/>
						<h2
							className="mb-16 mt-16"
							style={{ color: "var(--fontColor-secondary)", fontSize: "16px", fontWeight: "500" }}
						>
							{this.state.popupText}
						</h2>
						<h3 className="mb-16" style={{ color: "inherit" }}>
							Signup or Login
						</h3>
						{this.state.showPhone ? (
							<EnterMobile mob_handler={this.mobHandler} />
						) : (
							<EnterOTP
								otp_handler={this.otpHandler}
								mob_number={this.state.mob}
								first_time_user={this.state.first_time_user}
							/>
						)}
					</div>
					<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 social-login-pop">
						<div className="mt-4">
							OR
						</div>
						<div className="row social-padding-top">
							<div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
								<a className="google-button" href={this.state.googleLoginURL}>
									<i className="fa fa-google"></i> Continue with Google
								</a>
							</div>
						</div>
						{/* <div className="row social-padding-top">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <a className="google-button" href={this.state.facebookLoginURL}>
                  <i className="fa fa-facebook"></i> Sign In/Sign Up with Facebook
                </a>
              </div>
            </div> */}
					</div>
				</div>
			</div>
		);
	}
}

export default LoginPopup;
