import React, { Component } from "react";
import LazyLoad from "../Commons/LazyLoad";

class ExposureReportCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			table_data: [],
		};
	}

	componentDidMount() {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/${this.props.student_id}/experiences/subjects`;

		fetch(url, requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					// this.setState({
					// 	// table_data: result.data.results
					// 	table_data: []
					// });
				},
				(error) => {}
			);
	}

	render() {
		return (
			<div className="exposure-report-div">
				<h2>Your Exposure Report (Subject wise)</h2>
				{this.state.table_data.length ? (
					this.state.table_data.map((data, index) => (
						<div className="indiv-exposure-card row">
							<h5>{data.name}</h5>
							<p>Mentors you interacted with:</p>
							{data.mentors !== null ? (
								data.mentors.map((mentor) => (
									<div className="col-12 col-sm-6 col-lg-12 col-xl-6 mentor-det-dashboard-table d-flex justify-content-around">
										<LazyLoad
											src={mentor.avatar_url}
											classes="profile-pic-dashboard-table"
											alt="profile-pic-dashboard-table"
										/>
										<span className="mentor-name-dasbhoard-table">
											{mentor.first_name}
										</span>
									</div>
								))
							) : (
								<td className="no-upcoming-webinar">None Yet.</td>
							)}
						</div>
					))
				) : (
					<tr className="dashboard-exposure-no-tr">
						<td className="no-bookings-yet dashboard-exposure-no-td">
							<div>
								<LazyLoad
									src="/icons/history.png"
									classes="calendar-dashboard"
									alt=""
								/>
							</div>{" "}
							<div>Uh oh. No reports found :/</div>
						</td>
					</tr>
				)}
			</div>
		);
	}
}

export default ExposureReportCard;
