import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";
import UpcomingExperiences from "./UpcomingExperiences";
import Banner from "./Banner";
// import { Redirect } from 'react-router';

class Experience extends Component {
	componentDidMount() {
		document.title = "Workshops | HelloWorlds";
	}
	render() {
		//if (this.props.access_token===null) {
		// return <Redirect to='/login'/>;
		//}
		return (
			<div>
				<Header
					update_token={this.props.update_token}
					current_route={this.props.current_route}
					avatar_url={this.props.avatar_url}
					access_token={this.props.access_token}
				/>
				<Banner />
				<UpcomingExperiences access_token={this.props.access_token} />
				<Footer />
			</div>
		);
	}
}

export default Experience;
