import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";

import UpcomingExperienceCard from "./UpcomingExperienceCard";
import UpcomingCourseCard from "./UpcomingCourseCard";
import UpcomingLiveRooms from "./UpcomingLiveRooms";
import JoinedClubs from "./JoinedClubs";

//var upcomingDateTime = require('../utils.js').upcomingDateTime;

class UpcomingExperienceCourse extends Component {
	render() {
		return (
			<div className="col-lg-8 col-12 no-mob-padding dashboard-cards-wrapper">
				<JoinedClubs
					student_id={this.props.student_id}
					access_token={this.props.access_token}
					student_uuid={this.props.student_uuid}
				/>
				<UpcomingLiveRooms
					student_id={this.props.student_id}
					access_token={this.props.access_token}
					student_uuid={this.props.student_uuid}
					dark={this.props.dark}
				/>
				{/* <UpcomingExperienceCard
					student_id={this.props.student_id}
					access_token={this.props.access_token}
					student_uuid={this.props.student_uuid}
				/>
				<UpcomingCourseCard
					student_id={this.props.student_id}
					access_token={this.props.access_token}
					student_uuid={this.props.student_uuid}
				/> */}
			</div>
		);
	}
}

export default UpcomingExperienceCourse;
