import React, { Component } from "react";
import Header from "../Header";
import swal from "sweetalert";
import { Redirect } from "react-router";
// import OtpInput from "react-otp-input";

import Footer from "../Footer";
import ReactDOM from "react-dom";
import InterestPopup from "./InterestPopup";
import AppFloatingInput from "../AppComponents/AppFloatingInput";
import { Button, Form } from "react-bootstrap";
import DobPicker from "../AppComponents/DobPicker";
import ImageCropper from "./ImageCropper";

class EditProfile extends Component {
	componentDidMount() {
		document.title = "Edit Profile | HelloWorlds";
		if (this.props.show_msg) {
			//console.log("hey");
			var swal_welcome_content = document.createElement("div");
			swal_welcome_content.innerHTML =
				"<p>Let's get started by helping us with a few basic details.</p>";
			swal({
				title: "Welcome to the world of HelloWorlds!",
				content: swal_welcome_content,
			});
		}
	}

	render() {
		if (this.props.access_token === null) {
			return <Redirect to="/login" />;
		}
		return (
			<div style={{ overflow: "hidden" }}>
				<Header
					avatar_url={this.props.avatar_url}
					access_token={this.props.access_token}
				/>
				<video className="video-background" autoPlay loop muted>
					<source src="/bg.mp4" type="video/mp4" />
					Your browser does not support the video tag.
				</video>
				<div className="row edit-profile-container video-bg-content">
					<InputBox
						update_image_state={this.props.update_image_state}
						disable_redirect_to_profile={this.props.disable_redirect_to_profile}
						access_token={this.props.access_token}
						student_uuid={this.props.student_uuid}
						dark={this.props.dark}
					/>
				</div>
				<Footer />
			</div>
		);
	}
}


class InputBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			first_name: null,
			last_name: null,
			email: null,
			dob: null,
			mobile_number: null,
			avatar_url: "/student.png",
			//school_id: 0,
			//school_option: {"value": 0, "label": "test"},
			city_option: { value: 2, label: "Bengaluru" },
			city_id: 0,
			grade: 0,
			section: "A",
			//schools: [],
			cities: [],
			redirect_to_home: false,
			enable_mob_change: false,
			first_time_signup: 0,
			interests: [],
		};
		this.editProfile = this.editProfile.bind(this);
		this.pushMoengageEvent = this.pushMoengageEvent.bind(this);
	}

	componentDidMount() {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + this.props.access_token);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		let url1 = `${process.env.REACT_APP_BASE_URL}/rest/cities`;
		let url2 = `${process.env.REACT_APP_BASE_URL}/rest/students/${this.props.student_uuid}`;

		Promise.all([fetch(url1, requestOptions), fetch(url2, requestOptions)])
			.then(([res1, res2]) => {
				return Promise.all([res1.json(), res2.json()]);
			})
			.then(
				([result1, result2]) => {
					// console.log("Result");
					this.setState({
						first_name: result2["data"].first_name,
						last_name: result2["data"].last_name,
						email: result2["data"].email,
						dob: result2["data"].dob,
						mobile_number: result2["data"].mobile_number,
						avatar_url: result2["data"].avatar_url,
						//schools: result1["data"].results
						cities: result1.data.results,
						interests: result2["data"].interest,
					});

					if (result2["data"].classroom !== null) {
						this.setState({
							//school_id: result2["data"].classroom.institution.id,
							//school_option: {value: result2["data"].classroom.institution.id, label: result2["data"].classroom.institution.name},
							grade: result2["data"].classroom.grade,
							section: result2["data"].classroom.section,
						});
					}

					if (result2["data"].city !== null) {
						this.setState({
							city_option: {
								value: result2["data"].city.id,
								label: result2["data"].city.name,
							},
							city_id: result2["data"].city.id,
						});
					}

					if (result2["data"].first_name === null) {
						this.setState({
							first_time_signup: 1,
						});
					}
				},
				(error) => { }
			);
	}

	uploadImage(image) {
		const { access_token } = this.props;
		const myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${access_token}`);

		const formdata = new FormData();

		image.forEach((image, index) => {
			formdata.append(`media[${index}]`, image, image.name);
		});

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(`${process.env.REACT_APP_BASE_URL}/rest/media`, requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					this.setState({
						avatar_url: result["data"]?.url[0],
					});
				},
				(error) => { }
			);
	}

	changeValue(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	changeCityValue(e) {
		this.setState({
			city_id: e.target.value,
			city_option: { value: e.target.value },
		});
	}

	editProfile() {
		if (this.state.first_name === null) {
			swal("Please enter your first name");
		} else if (this.state.email === null) {
			swal("Please enter your email.");
		} else if (this.state.dob === null) {
			swal("Please enter your Date of Birth.");
		} else if (this.state.school_id === 0) {
			swal("Please select your school.");
		} else if (this.state.grade === 0) {
			swal("Please select your grade.");
		} else if (this.state.mobile_number === null) {
			swal("Please enter your mobile_number.");
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + this.props.access_token);
			myHeaders.append("Content-Type", "application/json");

			var raw = JSON.stringify({
				first_name: this.state.first_name,
				last_name: this.state.last_name,
				email: this.state.email,
				avatar_url: this.state.avatar_url,
				dob: this.state.dob,
				city_id: this.state.city_id,
				school_id: this.state.school_id,
				grade: this.state.grade,
				section: this.state.section,
				mobile_number: this.state.mobile_number,
			});

			var requestOptions = {
				method: "PUT",
				headers: myHeaders,
				body: raw,
				redirect: "follow",
			};

			let url = `${process.env.REACT_APP_BASE_URL}/rest/students/${this.props.student_uuid}`;

			fetch(url, requestOptions)
				.then((res) => res.json())
				.then(
					(result) => {
						if (result.success === true) {
							this.pushMoengageEvent();
						}
					},
					(error) => { }
				);
		}
	}

	pushMoengageEvent() {
		if (this.state.first_time_signup === 1) {
			//alert("Pushing to Moengage.");
			window.Moengage.add_first_name(this.state.first_name);
			window.Moengage.add_last_name(this.state.last_name);
			window.Moengage.add_email(this.state.email);
			window.Moengage.add_user_name(
				this.state.first_name + " " + this.state.last_name
			);

			window.Moengage.track_event("SignUp", {
				first_name: this.state.first_name,
				last_name: this.state.last_name,
				grade: this.state.grade,
				age: this.state.dob,
				email: this.state.email,
				mobile_number: this.state.mobile_number,
			});
		}

		swal({
			title: "Profile Updated Successfully.",
			text: "",
		}).then((okay) => {
			this.props.disable_redirect_to_profile();
			this.props.update_image_state(this.state.avatar_url);
			//alert("redirecting now");
			window.location = "/worlds";
			//this.setState({redirect_to_home: true});
			//alert(this.state.redirect_to_home);
		});
	}

	showOTPBox() {
		swal({
			text: "Mobile Number change has currently been disabled. You can login from your other number or write to us at hello@helloworlds.co to change the mobile number on your account.",
		});
	}

	interestModal = () => {
		if (this.state.interests) {
			var swal_interest_popup = document.createElement("div");
			ReactDOM.render(
				<React.StrictMode>
					<InterestPopup
						access_token={this.props.access_token}
						student_uuid={this.props.student_uuid}
						interest={this.state.interests}
					/>
				</React.StrictMode>,
				swal_interest_popup
			);

			swal({
				content: swal_interest_popup,
				buttons: false,
				className: "swal-interest",
				closeOnClickOutside: true,
			});
		}
	};

	render() {
		let avatar_url = "/student.png";
		if (this.state.avatar_url !== null) {
			avatar_url = this.state.avatar_url;
		}

		var cityOptions = [{ value: 0, label: "Please select your city" }];
		let gradeOption = [
			{ value: 0, label: "Please select your grade" },
			{ value: 1, label: "1" },
			{ value: 2, label: "2" },
			{ value: 3, label: "3" },
			{ value: 4, label: "4" },
			{ value: 5, label: "5" },
			{ value: 6, label: "6" },
			{ value: 7, label: "7" },
			{ value: 8, label: "8" },
			{ value: 9, label: "9" },
			{ value: 10, label: "10" },
			{ value: 11, label: "11" },
			{ value: 12, label: "12" },
		];

		if (this.state.cities) {
			this.state.cities.forEach((city) => {
				cityOptions.push({ value: city.id, label: city.name });
			});
		}

		let home_url = "/";
		if (
			this.state.redirect_to_home === true &&
			this.state.first_time_signup === 1
		) {
			home_url = "/?first_time_signup=1";
		}

		return this.state.redirect_to_home ? (
			<Redirect to={home_url} />
		) : (
			<div className="col-12 col-md-10 col-lg-8 mx-auto edit-input-container mt-4">
				<h1 className="ms-2 text-white">Account Settings</h1>

				<div className="row align-items-center edit-first">
					<div className="edit-profile-photo col-4 col-lg-6">
						<img src={avatar_url} className="edit-profile-img" alt="" />
					</div>
					<div className="col-lg-6 col-8">
						<ImageCropper
							handleSubmit={(e) => {
								this.uploadImage(e);
							}}
							dark={this.props.dark}
						/>
					</div>
				</div>

				<div className="row edit-2">
					<div className="col-9 px-0 pe-2">
						<AppFloatingInput
							value={this.state.mobile_number}
							label="Mobile Number"
							placeholder="Mobile Number"
							name="mobile_number"
							onChange={(event) => {
								this.changeValue(event);
							}}
							style={
								this.props.dark
									? {
										boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
										background: "var(--background_primary)",
									}
									: {
										boxShadow: "#d9d9d9 0px 2px 10px",
										background: "var(--background_primary)",
									}
							}
						/>
					</div>
					<Button
						className="col-3 dark-border"
						style={{
							background: "none",
							borderWidth: "2px",
							color: "var(--fontColor-primary)",
							fontSize: "0.7rem",
							padding: "0.1rem 0.5rem",
							borderRadius: "10px",
							color:"#fff"
						}}
						onClick={this.showOTPBox}
					>
						Change
					</Button>
				</div>

				<div className="row">
					<div className="col-md-6 px-0 pe-2 my-3">
						<AppFloatingInput
							label="First Name"
							type="text"
							name="first_name"
							defaultValue={this.state.first_name}
							onChange={(event) => {
								this.changeValue(event);
							}}
							style={
								this.props.dark
									? {
										boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
										background: "var(--background_primary)",
									}
									: {
										boxShadow: "#d9d9d9 0px 2px 10px",
										background: "var(--background_primary)",
									}
							}
						/>
					</div>
					<div className="col-md-6 px-0 pe-2 my-3">
						<AppFloatingInput
							label="Last Name"
							type="text"
							name="last_name"
							defaultValue={this.state.last_name}
							onChange={(event) => {
								this.changeValue(event);
							}}
							style={
								this.props.dark
									? {
										boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
										background: "var(--background_primary)",
									}
									: {
										boxShadow: "#d9d9d9 0px 2px 10px",
										background: "var(--background_primary)",
									}
							}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-6 px-0 pe-2 my-3">
						<AppFloatingInput
							label="Email"
							type="email"
							name="email"
							defaultValue={this.state.email}
							onChange={(event) => {
								this.changeValue(event);
							}}
							style={
								this.props.dark
									? {
										boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
										background: "var(--background_primary)",
									}
									: {
										boxShadow: "#d9d9d9 0px 2px 10px",
										background: "var(--background_primary)",
									}
							}
						/>
						<p style={{ fontSize: "14px" }} className="ms-2 text-muted mt-1">
							We will only use your email to send reminders/alerts.
						</p>
					</div>
					<div className="col-md-6 px-0 pe-2 my-3 dob-picker-w">
						<p className="position-absolute">
							Date of Birth
						</p>
						<DobPicker
							label="Date of Birth"
							type="date"
							name="dob"
							value={this.state.dob}
							onChange={(value) => {
								this.setState({ dob: value })
							}}
							style={
								this.props.dark
									? {
										boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
										background: "var(--background_primary)",
									}
									: {
										boxShadow: "#d9d9d9 0px 2px 10px",
										background: "var(--background_primary)",
									}
							}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-md-6 px-0 pe-2 my-3">
						<AppFloatingInput
							name="city_id"
							selectOptions={cityOptions}
							value={this.state.city_option.value}
							placeholder="Please select your city"
							label="Please select your city"
							onChange={(event) => {
								this.changeCityValue(event);
							}}
							style={
								this.props.dark
									? {
										boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
										background: "var(--background_primary)",
									}
									: {
										boxShadow: "#d9d9d9 0px 2px 10px",
										background: "var(--background_primary)",
									}
							}
						/>
					</div>
					<div className="col-md-6 px-0 pe-2 my-3">
						<AppFloatingInput
							name="grade"
							selectOptions={gradeOption}
							placeholder="Please select your grade"
							label="Please select your grade"
							value={this.state.grade}
							onChange={(event) => {
								this.changeValue(event);
							}}
							style={
								this.props.dark
									? {
										boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
										background: "var(--background_primary)",
									}
									: {
										boxShadow: "#d9d9d9 0px 2px 10px",
										background: "var(--background_primary)",
									}
							}
						/>
					</div>
				</div>

				<div
					style={
						this.props.dark
							? {
								boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
								background: "var(--background_primary)",
							}
							: {
								boxShadow: "#d9d9d9 0px 2px 10px",
								background: "var(--background_primary)",
							}
					}
					className="profile-interests"
				>
					<h6>
						My Interests
					</h6>
					<div className="d-flex flex-wrap align-items-center gap-3">
						{
							this.state.interests.map((interest, index) => {
								return (
									<span key={index} className="profile-interest">
										#{interest.name}
									</span>
								);
							})
						}
						<Button
							className="col-3 dark-border"
							style={{
								background: "none",
								borderWidth: "2px",
								color: "var(--fontColor-primary)",
								padding: "5px",
								width: "100px",
								margin: "15px 0",
								borderRadius: "10px",
							}}
							onClick={this.interestModal}
						>
							Edit
						</Button>
					</div>

				</div>

				<button
					type="button"
					className="edit-profile-btn py-2 ms-2"
					style={{ padding: "0 2.4rem" }}
					onClick={this.editProfile}
				>
					Save Changes
				</button>

				<input
					type="text"
					name="section"
					className="profile-input hide-it"
					defaultValue={this.state.section}
					onChange={(event) => {
						this.changeValue(event);
					}}
					style={
						this.props.dark
							? {
								boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
								background: "var(--background_primary)",
							}
							: {
								boxShadow: "#d9d9d9 0px 2px 10px",
								background: "var(--background_primary)",
							}
					}
				/>

			</div>
		);
	}
}

export default EditProfile;
