import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";
import SideHeader from "./SideHeader";
import PastExpBookings from "./PastExpBookings";
import PastCourseBookings from "./PastCourseBookings";
import PastWorlds from "./PastWorlds";
import { Redirect } from "react-router";

class ExpCourseHome extends Component {
	componentDidMount() {
		document.title = "My Journey | HelloWorlds";
	}

	render() {
		if (this.props.access_token === null) {
			return <Redirect to="/login" />;
		}
		return (
			<div>
				<Header
					update_token={this.props.update_token}
					avatar_url={this.props.avatar_url}
					access_token={this.props.access_token}
				/>
				<video className="video-background" autoPlay loop muted>
					<source src="/bg.mp4" type="video/mp4" />
					Your browser does not support the video tag.
				</video>
				<div className="past-recording-home row video-bg-content" >
					{/* <div className="col-12 col-md-6">
						<SideHeader current_route={this.props.current_route} dark={this.props.dark} />
					</div> */}
					<div
						className="col-12 col-md-6 d-none d-md-block"
						style={{
							position: "sticky",
							top: "100px",
							height: "100vh",
						}}
					>
						<img
							src="/myworlds.png"
							style={{
								width: "100%",
							}}
						/>
					</div>
					<div
						className="col-12 col-md-6 whole-past-recording"
						style={
							this.props.dark
								? {
									boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
									background: "var(--background_primary)",
								}
								: {
									boxShadow: "#d9d9d9 0px 2px 10px",
									background: "var(--background_primary)",
								}
						}
					>
						<h2>
							{
								this.props.current_route === "recording-world" ? "Your Worlds" : "Completed"
							}
						</h2>

						{
							this.props.current_route === "recording-exp" && (
								<PastExpBookings
									student_id={this.props.student_id}
									student_uuid={this.props.student_uuid}
									access_token={this.props.access_token}
									dark={this.props.dark}
								/>
							)
						}
						{
							this.props.current_route === "recording-course" && (
								<PastCourseBookings
									student_id={this.props.student_id}
									student_uuid={this.props.student_uuid}
									access_token={this.props.access_token}
									dark={this.props.dark}
								/>
							)
						}
						{
							this.props.current_route === "recording-world" && (
								<PastWorlds
									student_id={this.props.student_id}
									student_uuid={this.props.student_uuid}
									access_token={this.props.access_token}
									dark={this.props.dark}
								/>
							)
						}
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default ExpCourseHome;
