import { Container, Row, Col, Tabs, Tab, } from "react-bootstrap"
import styles from './NewMentor.module.css'
import { RWebShare } from "react-web-share";
import { useState, useEffect, StrictMode } from "react";
import { useParams } from "react-router-dom";
import AboutMentor from "./AboutMentor";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import LazyLoad from "../Commons/LazyLoad";
import ReactDOM from "react-dom";
import LoginPopup from "../Auth/LoginPopup";
import swal from "sweetalert";
import { capitalizeName } from "../utils";
import { createChannel, addChannelMembers, getChannels } from "../StreamChat/utils";

const stripHtml = (text = "") => {
    const regex = /(<([^>]+)>)/gi;
    let data = text.replace(regex, "");
    data = data.replace(/&nbsp;/g, " ");
    data = data.replace(/Powered by Froala Editor/, "");
    return data;
};

/**
 * For handling profile picture
 * @param {string} image url
 * @returns {string} url if image is not null or undefined or empty string else default image
 */
const handleProfilePic = (img) => {
    if (img === null || img === undefined) {
        return "/images/teacher.png"
    } else if (img === "" || img === "NA") {
        return "/images/teacher.png"
    } else {
        return img
    }
}

const NewMentor = (props) => {
    const [key, setKey] = useState('about'); // for tabs
    const [mentorData, setMentorData] = useState([]);
    const [worlds, setWorlds] = useState([]);
    const { slug } = useParams();
    const [chatLoading, setChatLoading] = useState(false);

    document.title = `${mentorData?.first_name} ${mentorData?.last_name} | HelloWorlds`;

    const handleChat = async () => {
        let swal_popup = null;

        if (!!props.access_token) {
            setChatLoading(true);
            try {
                // checking if channel already exists with the same user
                const existingChannels = await getChannels();
                if (existingChannels?.data?.channels?.length) {
                    const existingChannel = existingChannels?.data?.channels?.find((channel) => {
                        return channel?.members[0]?.user?.id === mentorData?.uuid?.uuid
                    })
                    // If channel exists, redirect to that channel
                    if (existingChannel) {
                        props?.history.push("/chats", { channel: existingChannel })
                        return
                    }
                }

                // If channel doesn't exist, create a new channel
                const createChannelRes = await createChannel({
                    group: 0,
                    // world_id: data?.world_id,
                    name: `${mentorData?.first_name} ${mentorData?.last_name}`,
                });

                // If channel is created, add the user to the channel
                if (createChannelRes?.data?.channel) {
                    const addMemberRes = await addChannelMembers({
                        channel_name: createChannelRes?.data?.channel?.id,
                        members: [mentorData?.uuid?.uuid],
                    });
                    if (addMemberRes?.data) {
                        props?.history.push("/chats", { channel: addMemberRes?.data })
                        return
                    } else {
                        throw new Error("Something went wrong")
                    }
                }
            } catch (error) {
                console.error(error)
            } finally {
                setChatLoading(false);
            }
        }

        swal_popup = document.createElement("div");
        ReactDOM.render(
            <StrictMode>
                <LoginPopup update_token={props.update_token} />
            </StrictMode>,
            swal_popup
        );
        swal({
            content: swal_popup,
            buttons: false,
            className: "swal-login",
            closeOnClickOutside: false,
        });
    };

    useEffect(() => {
        let url = `${process.env.REACT_APP_BASE_URL}/rest/students/mentors/${slug}/slug`;
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                setMentorData(data.data);
                setWorlds(data.worlds);
            }).catch((err) => console.log(err));
    }, [slug])

    useEffect(() => {
        if (mentorData?.uuid?.uuid) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + props.access_token);

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            let url = `${process.env.REACT_APP_BASE_URL}/rest/students/world?mentor_id=${mentorData?.uuid?.uuid}`;

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    setWorlds(result.data.results);
                }).catch((error) => console.log("error", error))
        }

    }, [mentorData?.uuid?.uuid])

    return (
        <>
            <Header
                update_token={props?.update_token}
                avatar_url={props?.avatar_url}
                access_token={props?.access_token}
            />

            <video className="video-background" autoPlay loop muted>
                <source src="/bg.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <Container className={`${styles.mentorContainer} video-bg-content`}>
                <Row>
                    <Col md={4}>
                        <div className={styles.leftSticky}>
                            <div className={styles.imgBox} >
                                <img
                                    src={handleProfilePic(mentorData?.avatar_url)}
                                    alt={mentorData?.first_name}
                                />
                                <h5>
                                    {
                                        `${mentorData?.first_name} ${mentorData?.last_name}`
                                    }
                                </h5>
                            </div>

                            <div className={styles.buttons}>
                                <button className={styles.shareBtn}>
                                    <RWebShare
                                        data={{
                                            text:
                                                "Hey, Checkout this Mentor on HelloWorlds: " + `${mentorData?.first_name} ${mentorData?.last_name}`,
                                            url:
                                                `https://app.helloworlds.co/mentor/${slug}`,
                                            title: `${mentorData?.first_name} ${mentorData?.last_name}`,
                                        }}
                                    >
                                        <div className={styles.shareBtnInner}>
                                            <span>Share</span>
                                            <img
                                                src="/icons/share.png"
                                                alt="Share Icon"
                                            />
                                        </div>
                                    </RWebShare>
                                </button>
                                <button
                                    className={styles.msgBtn}
                                    onClick={handleChat}
                                    disabled={chatLoading}
                                >
                                    Message
                                </button>
                            </div>

                            <div className={styles.intro}>
                                <h5>
                                    Intro
                                </h5>
                                <p>
                                    {
                                        mentorData?.one_liner
                                    }

                                </p>
                                <p className="mt-3 text-info">
                                    <img
                                        src="/icons/location.png"
                                        alt="helloworlds"
                                        style={{
                                            width: "25px",
                                            marginRight: "5px",
                                            filter: props?.dark ? "invert(1)" : "invert(0)",
                                        }}
                                    />
                                    {
                                        mentorData?.city?.name
                                    }
                                </p>
                            </div>
                        </div>
                    </Col>

                    <Col md={8}>
                        <Tabs
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                gap: "1rem",
                                boxShadow: "var(--shadow_primary)",
                                borderRadius: "30px",
                                background: "var(--background_primary)",
                            }}
                        >
                            <Tab eventKey="about" title="About">
                                <div className={styles.tabBox}>
                                    {
                                        mentorData?.about_me ? (
                                            <AboutMentor about_me={mentorData?.about_me} />
                                        ) : (
                                            <div className={styles.noWorlds}>
                                                <h5>No About</h5>
                                            </div>
                                        )
                                    }
                                </div>
                            </Tab>

                            <Tab eventKey="worlds" title="Worlds">
                                <div className={styles.tabBoxWorlds}>
                                    {
                                        worlds?.length ? (
                                            worlds.map((world) => (
                                                <div
                                                    key={world?.id}
                                                    className="experience-snippet-dashboard mb-5 pb-2"
                                                    style={
                                                        props.dark
                                                            ? {
                                                                boxShadow: "rgb(136 136 136 / 30%) 0px 2px 20px",
                                                                background: "var(--background_primary)",
                                                                maxWidth: window.innerWidth > 768 ? "250px" : "100%",
                                                                minHeight: "320px",
                                                            }
                                                            : {
                                                                boxShadow: "#d9d9d9 0px 2px 10px",
                                                                background: "var(--background_primary)",
                                                                maxWidth: window.innerWidth > 768 ? "250px" : "100%",
                                                                minHeight: "320px",
                                                            }
                                                    }
                                                >
                                                    <Link to={`/worlds/${world?.id}`}>
                                                        <LazyLoad
                                                            src={world?.image}
                                                            alt="snippet-dashboard-img"
                                                            classes="snippet-dashboard-img"
                                                        />
                                                    </Link>
                                                    <div className="exp-snippet-dashboard-texts">
                                                        <p
                                                            className="mb-1"
                                                            style={{ fontSize: "14px" }}
                                                        >
                                                            {world?.primary_subject?.name}
                                                        </p>
                                                        <h3 className="snippet-dashboard-text">
                                                            {world?.title}
                                                        </h3>
                                                        <p style={{ color: "var(--fontColor-eo)" }}>

                                                        </p>
                                                        <p className="snippet-with-dashboard-text">
                                                            {world.tagline && (
                                                                stripHtml(world.tagline).slice(0, 100) + "..."
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className={styles.noWorlds}>
                                                <h5>No Worlds created by {mentorData?.first_name}  yet.</h5>
                                            </div>

                                        )
                                    }
                                </div>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
            <div className="clearfix"></div>
            <Footer />
        </>
    )
}

export default NewMentor