import moment from "moment";
import styles from "./EventCard.module.css";
import { useState } from "react";
import swal from "sweetalert";
import { Spinner } from "react-bootstrap";

const EventCard = (props) => {
	let data = props.data;
	let event_url = props?.past ? "/room/" + data.id + "/recording" : "/room/" + data.id;
	let duration = moment(data.end_time_ts).diff(moment(data.start_time_ts), "minutes");
	const [loading, setLoading] = useState(false);

	const handleUpcomingEvent = () => {
		props.history.push(event_url, { path: "worlds" })
	}

	const handlePastEvent = async () => {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + props.access_token);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		let url = `${process.env.REACT_APP_BASE_URL}/rest/students/webinars/${data?.id}/recording`;
		try {
			setLoading(true);
			const ressponse = await fetch(url, requestOptions);
			const result = await ressponse.json();
			if (result?.data?.recording === "This recording does not exist.") {
				swal(
					"This recording does not exist."
				);
			} else {
				props.history.push(event_url, { path: "worlds" })
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}

	}

	return (
		<div className={styles.eventCard}>
			<div className={styles.left}>
				<div className={styles.date}>
					<p>
						{moment(data.start_time_ts).format("DD")}
					</p>
					<p>
						{moment(data.start_time_ts).format("MMM")}
					</p>
				</div>
				<div className={styles.content}>
					<h5>
						{data?.title}
					</h5>
					<div className={styles.features}>
						<div className={styles.feature}>
							{moment(data.start_time_ts).format("h:mm A")}
						</div>

						<div className={styles.feature}>
							{duration} mins
						</div>

						<div className={styles.feature}>
							{data.room_type
								? data?.room_type
								: "Workshop"}
						</div>
					</div>
				</div>
			</div>

			<div className={styles.right}>
				<div className={styles.date}>
					<p>
						{moment(data.start_time_ts).format("DD")}
					</p>
					<p>
						{moment(data.start_time_ts).format("MMM")}
					</p>
				</div>
				<button
					className="snippet-dashboard-attend"
					disabled={props.sneakPeek || loading}
					style={props.sneakPeek ? { cursor: "not-allowed" } : {}}
					onClick={props.past ? handlePastEvent : handleUpcomingEvent}
				>
					{
						loading ? <Spinner animation="border" variant="light" size="sm" /> : "View"
					}
				</button>
			</div>
		</div>
	);
};

export default EventCard;
